import dayjs from 'dayjs';
import { getAPIPath } from 'getAPIPath';
import { useIsLogedIn } from 'hooks/useIsLogedIn';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SegmentMatch } from 'state/segment';

export default function FFACalc({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  const { loading } = useIsLogedIn();
  const [tcName, setTcName] = useState<string>('5TC_C-FFA');
  const [durationMonths, setDurationMonths] = useState<number>(1);
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
  const [apiResult, setApiResult] = useState<string | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    (async () => {
      setApiResult(null);
      setLoadingData(true);
      const response = await fetch(
        `${getAPIPath()}/TC_FFA_calc?tc_name=${tcName}&duration_months=${durationMonths}&delivery_date=${dayjs(
          deliveryDate
        ).format('YYYY-MM-DD')}`
      );
      const data = await response.json();
      setLoadingData(false);
      setApiResult(JSON.stringify(data));
    })();
  };
  if (loading) {
    return <></>;
  }
  if ((segmentStateValue as string) !== SegmentMatch.DryCargo) {
    return <></>;
  }
  return (
    <>
      <FFAForm
        tcName={tcName}
        setTcName={setTcName}
        durationMonths={durationMonths}
        setDurationMonths={setDurationMonths}
        deliveryDate={deliveryDate}
        setDeliveryDate={setDeliveryDate}
        handleSubmit={handleSubmit}
        apiResult={apiResult}
        loadingData={loadingData}
      />
    </>
  );
}

function FFAForm({
  tcName,
  setTcName,
  durationMonths,
  setDurationMonths,
  deliveryDate,
  setDeliveryDate,
  handleSubmit,
  apiResult,
  loadingData,
}: {
  tcName: string;
  setTcName: (value: string) => void;
  durationMonths: number;
  setDurationMonths: (value: number) => void;
  deliveryDate: Date;
  setDeliveryDate: (value: Date) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  apiResult: string | null;
  loadingData: boolean;
}) {
  return (
    <>
      <form onSubmit={handleSubmit} className="tw-p-2 tw-text-center">
        <label>
          TC Name:
          <select
            className="tw-m-2 tw-p-1 tw-border tw-border-gray-400 tw-rounded"
            value={tcName}
            onChange={(e) => setTcName(e.target.value)}
          >
            <option value="5TC_C-FFA">5TC_C</option>
            <option value="5TC_P-FFA">5TC_P</option>
            <option value="P1A_82-FFA">P1A</option>
            <option value="P2A_82-FFA">P2A</option>
            <option value="P3A_82-FFA">P3A</option>
            <option value="10TC_S-FFA">10TC_S</option>
            <option value="TC_H38-FFA">TC_H38</option>
          </select>
        </label>
        <label>
          Duration Months:
          <input
            className="tw-m-2 tw-p-1 tw-border tw-border-gray-400 tw-rounded"
            type="number"
            min={1}
            max={64}
            value={durationMonths}
            onChange={(e) => setDurationMonths(+e.target.value)}
          />
        </label>
        <label>
          Delivery Date:
          <input
            className="tw-m-2 tw-p-1 tw-border tw-border-gray-400 tw-rounded"
            type="date"
            min={dayjs().format('YYYY-MM-DD')}
            value={dayjs(deliveryDate).format('YYYY-MM-DD')}
            onChange={(e) => setDeliveryDate(new Date(e.target.value))}
          />
        </label>
        <Button type="submit" value="Submit" variant="dark">
          Calculate
        </Button>
      </form>
      {loadingData && (
        <svg
          className="tw-h-5 tw-w-5 tw-text-gray-900 tw-text-center tw-mx-auto tw-animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            opacity="0.2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="black"
          />
          <path
            d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
            fill="black"
          />
        </svg>
      )}
      {apiResult && (
        <div className="tw-p-2 tw-text-center tw-text-2xl">
          <div className=" tw-font-bold tw-font-sans">
            Result:{' '}
            <span className="tw-text-green-600 tw-underline">{apiResult}</span>
          </div>
        </div>
      )}
    </>
  );
}
