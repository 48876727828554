import { Spinner } from 'react-bootstrap';

export const AFSpinner = () => {
  return (
    <div>
      <div className="spinner" style={{ textAlign: 'center', marginTop: 50 }}>
        <Spinner animation="border" variant="info" />
      </div>
    </div>
  );
};
