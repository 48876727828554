import Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';

HighchartsExporting(Highcharts);
export const DummyPage = () => {
  const rateData = {
    cached: true,
    response: [
      {
        header: 'Second Hand Values',
        data: [
          {
            name: 'Capesize/Newcastlemax 5 Year Old',
            timeSeries: [
              {
                date: '2019-11-11T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2019-11-18T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2019-11-25T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2019-12-02T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2019-12-09T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2019-12-16T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2019-12-30T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2020-01-06T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2020-01-13T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2020-01-20T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2020-01-27T00:00:00.000Z',
                value: 36.5,
              },
              {
                date: '2020-02-03T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-02-10T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-02-17T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-02-24T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-03-02T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-03-09T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-03-16T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-03-23T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-03-30T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-04-06T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-04-13T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-04-20T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-04-27T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-05-04T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-05-11T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-05-18T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-05-25T00:00:00.000Z',
                value: 35,
              },
              {
                date: '2020-06-01T00:00:00.000Z',
                value: 35,
              },
              {
                date: '2020-06-08T00:00:00.000Z',
                value: 35,
              },
              {
                date: '2020-06-15T00:00:00.000Z',
                value: 35,
              },
              {
                date: '2020-06-22T00:00:00.000Z',
                value: 35,
              },
              {
                date: '2020-06-29T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-07-06T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-07-13T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-07-20T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-07-27T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2020-08-03T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2020-08-10T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2020-08-17T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2020-08-24T00:00:00.000Z',
                value: 37,
              },
              {
                date: '2020-08-31T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-09-07T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-09-14T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-09-21T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-09-28T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-10-05T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-10-12T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-10-19T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-10-26T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-11-02T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-11-09T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-11-16T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-11-23T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-11-30T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-12-07T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-12-14T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-12-21T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2020-12-28T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-01-04T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-01-11T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-01-18T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-01-25T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-02-01T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-02-08T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-02-15T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-02-22T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-03-01T00:00:00.000Z',
                value: 36,
              },
              {
                date: '2021-03-08T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-03-15T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-03-22T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-03-29T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-04-05T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-04-12T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-04-19T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-04-26T00:00:00.000Z',
                value: 38,
              },
              {
                date: '2021-05-03T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-05-10T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-05-17T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-05-24T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-05-31T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-06-07T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-06-14T00:00:00.000Z',
                value: 42,
              },
              {
                date: '2021-06-21T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-06-28T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-07-05T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-07-12T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-07-19T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-07-26T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-08-02T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-08-09T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-08-16T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-08-23T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-08-30T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2021-09-06T00:00:00.000Z',
                value: 47,
              },
              {
                date: '2021-09-13T00:00:00.000Z',
                value: 47,
              },
              {
                date: '2021-09-20T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2021-09-27T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2021-10-04T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2021-10-11T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2021-10-18T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2021-10-25T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-11-01T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-11-08T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-11-15T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-11-22T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-11-29T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-12-06T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-12-13T00:00:00.000Z',
                value: 49.5,
              },
              {
                date: '2021-12-20T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2021-12-27T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-01-03T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-01-10T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-01-17T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-01-24T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-01-31T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-02-07T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-02-14T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-02-21T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-02-28T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-03-07T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-03-14T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2022-03-21T00:00:00.000Z',
                value: 47,
              },
              {
                date: '2022-03-28T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-04-04T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-04-11T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-04-18T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2022-04-25T00:00:00.000Z',
                value: 52,
              },
              {
                date: '2022-05-02T00:00:00.000Z',
                value: 52,
              },
              {
                date: '2022-05-09T00:00:00.000Z',
                value: 52,
              },
              {
                date: '2022-05-16T00:00:00.000Z',
                value: 52,
              },
              {
                date: '2022-05-23T00:00:00.000Z',
                value: 52,
              },
              {
                date: '2022-05-30T00:00:00.000Z',
                value: 52,
              },
              {
                date: '2022-06-06T00:00:00.000Z',
                value: 53,
              },
              {
                date: '2022-06-13T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-06-20T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-06-27T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-07-04T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-07-11T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-07-18T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-07-25T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-08-01T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-08-08T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-08-15T00:00:00.000Z',
                value: 54,
              },
              {
                date: '2022-08-22T00:00:00.000Z',
                value: 53,
              },
              {
                date: '2022-08-29T00:00:00.000Z',
                value: 53,
              },
              {
                date: '2022-09-05T00:00:00.000Z',
                value: 53,
              },
              {
                date: '2022-09-12T00:00:00.000Z',
                value: 50,
              },
              {
                date: '2022-09-19T00:00:00.000Z',
                value: 50,
              },
              {
                date: '2022-09-26T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2022-10-03T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2022-10-10T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2022-10-17T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2022-10-24T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2022-10-31T00:00:00.000Z',
                value: 45,
              },
              {
                date: '2022-11-07T00:00:00.000Z',
                value: 45,
              },
              {
                date: '2022-11-14T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2022-11-21T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2022-11-28T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2022-12-05T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2022-12-12T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2022-12-19T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2022-12-26T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2023-01-02T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2023-01-09T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2023-01-16T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2023-01-23T00:00:00.000Z',
                value: 43,
              },
              {
                date: '2023-01-30T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-02-06T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-02-13T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-02-20T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-02-27T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-03-06T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-03-13T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-03-20T00:00:00.000Z',
                value: 44,
              },
              {
                date: '2023-04-03T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2023-04-10T00:00:00.000Z',
                value: 46,
              },
              {
                date: '2023-04-17T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-04-24T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-05-01T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-05-10T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-05-24T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-05-31T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-06-07T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-06-14T00:00:00.000Z',
                value: 49,
              },
              {
                date: '2023-06-21T00:00:00.000Z',
                value: 48.5,
              },
              {
                date: '2023-06-28T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-07-05T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-07-12T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-07-19T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-07-26T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-08-02T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-08-09T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-08-16T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-08-30T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-09-06T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-09-13T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-09-20T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-09-27T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-10-04T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-10-11T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-10-18T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-10-25T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-11-01T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-11-08T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-11-15T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-11-22T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-11-29T00:00:00.000Z',
                value: 47.5,
              },
              {
                date: '2023-12-06T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2023-12-13T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2023-12-20T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2023-12-27T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2024-01-03T00:00:00.000Z',
                value: 48,
              },
              {
                date: '2024-01-10T00:00:00.000Z',
                value: 55,
              },
              {
                date: '2024-01-17T00:00:00.000Z',
                value: 55,
              },
              {
                date: '2024-01-24T00:00:00.000Z',
                value: 55,
              },
              {
                date: '2024-01-31T00:00:00.000Z',
                value: 55,
              },
              {
                date: '2024-02-07T00:00:00.000Z',
                value: 56.5,
              },
              {
                date: '2024-02-14T00:00:00.000Z',
                value: 56.5,
              },
              {
                date: '2024-02-21T00:00:00.000Z',
                value: 58,
              },
              {
                date: '2024-02-28T00:00:00.000Z',
                value: 58,
              },
              {
                date: '2024-03-06T00:00:00.000Z',
                value: 58,
              },
              {
                date: '2024-03-13T00:00:00.000Z',
                value: 58,
              },
              {
                date: '2024-03-20T00:00:00.000Z',
                value: 58,
              },
              {
                date: '2024-04-03T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-04-10T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-04-17T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-04-24T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-05-08T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-05-15T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-05-22T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-05-29T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-06-05T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-06-12T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-06-19T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-06-26T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-07-03T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-07-10T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-07-17T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-07-24T00:00:00.000Z',
                value: 60,
              },
              {
                date: '2024-07-31T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-08-07T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-08-14T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-08-21T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-08-28T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-09-04T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-09-11T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-09-18T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-09-25T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-10-02T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-10-09T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-10-16T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-10-23T00:00:00.000Z',
                value: 64,
              },
              {
                date: '2024-10-30T00:00:00.000Z',
                value: 63,
              },
            ],
          },
        ],
      },
    ],
    ttl: 1730897622932,
  };

  return (
    <div>
      <div>
        <div>
          <div>
            {rateData.response.map((e, k) => (
              <div key={k} style={{ marginBottom: '20px' }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    exporting: {
                      fetchOptions: {
                        headers: {
                          referer: 'https://fearnportal.com',
                        },
                      },
                      buttons: {
                        contextButton: {
                          menuItems: [
                            'downloadPNG',
                            'separator',
                            'downloadXLS',
                          ],
                          theme: {
                            fill: '#142E3D',
                          },
                          symbolFill: '#000000',
                        },
                      },
                      chartOptions: {
                        title: {
                          style: {
                            color: 'black', // black title
                          },
                        },
                        subtitle: {
                          text: '',
                          style: {
                            color: 'black',
                          },
                        },
                        plotOptions: {
                          pie: {
                            dataLabels: {
                              enabled: true,
                              color: 'black',
                            },
                          },
                          column: {
                            dataLabels: {
                              enabled: true,
                              color: 'black',
                            },
                          },
                        },
                        chart: {
                          backgroundColor: {
                            linearGradient: [0, 0, 0, 400],
                            stops: [
                              [0, '#F0F0F0'],
                              [1, '#FFFFFF'],
                            ],
                          },
                          borderWidth: 0,
                          borderRadius: 15,
                          plotBackgroundColor: null,
                          plotShadow: false,
                          plotBorderWidth: 0,
                        },
                        legend: {
                          itemStyle: {
                            color: '#CCC',
                          },
                          itemHoverStyle: {
                            color: '#FFF',
                          },
                          itemHiddenStyle: {
                            color: '#333',
                          },
                        },
                      },
                    },
                    chart: {
                      type: 'spline',
                      zoomType: 'x',
                      backgroundColor: '#142E3D',
                      borderWidth: 0,
                      borderRadius: 0,
                      plotBackgroundColor: null,
                      plotShadow: false,
                      plotBorderWidth: 0,
                    },
                    plotOptions: {
                      pie: {
                        dataLabels: {
                          enabled: true,
                          format: '<b>{point.name}</b>: {point.y}',
                          color: '#ffffff',
                        },
                      },
                      column: {
                        borderWidth: 0,
                        pointPadding: 0,
                        stacking: 'stream',
                        dataLabels: {
                          enabled: false,
                        },
                      },
                      line: {
                        dataLabels: {
                          color: '#CCC',
                        },
                        marker: {
                          lineColor: '#333',
                        },
                      },
                      spline: {
                        marker: {
                          lineColor: '#333',
                        },
                      },
                    },
                    colors: [
                      '#5787b2',
                      '#95D2CA',
                      '#D46C75',
                      '#8A84FF',
                      '#7FC2FB',
                      '#206273',
                      '#D46C75',
                      '#bfbfbf',
                      '#997D87',
                      '#0000A6',
                      '#63FFAC',
                      '#FF4A46',
                      '#5A0007',
                      '#809693',
                      '#FEFFE6',
                      '#1B4400',
                      '#4FC601',
                      '#3B5DFF',
                      '#4A3B53',
                      '#FF2F80',
                      '#61615A',
                      '#BA0900',
                      '#6B7900',
                      '#00C2A0',
                      '#FFAA92',
                      '#FF90C9',
                      '#B903AA',
                      '#D16100',
                      '#DDEFFF',
                      '#000035',
                      '#7B4F4B',
                      '#A1C299',
                    ],
                    xAxis: {
                      gridLineWidth: 0,
                      lineColor: '#999',
                      tickColor: '#999',
                      labels: {
                        style: {
                          color: '#999',
                          fontWeight: 'bold',
                        },
                      },
                      type: 'datetime',
                      title: {
                        style: {
                          color: '#AAA',
                          font: 'bold 12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                        },
                      },
                    },
                    toolbar: {
                      itemStyle: {
                        color: '#CCC',
                      },
                    },
                    legend: {
                      itemStyle: {
                        color: '#CCC',
                        fontSize: '9px',
                        font: '9pt Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                      },
                      itemHoverStyle: {
                        color: '#FFF',
                      },
                      itemHiddenStyle: {
                        color: '#333',
                      },
                    },
                    credits: {
                      style: {
                        fontSize: '15px',
                      },
                      enabled: true,
                      text: `© 2024 Fearnleys`,
                      href: 'https://www.fearnleys.com',
                      position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 100,
                        y: 100,
                      },
                    },
                    series: e.data.map(function (elem) {
                      return {
                        data: elem.timeSeries.map((element) => [
                          new Date(element.date).getTime(),
                          element.value
                            ? element.value
                            : Object.values(elem.timeSeries[0]),
                        ]),
                        name: elem.name
                          ? elem.name
                          : Object.keys(elem.timeSeries[0]),
                        yAxis: 0,
                        connectNulls: e.header.split(' ').includes('(rolling)')
                          ? true
                          : false,
                      };
                    }),
                    title: {
                      text: e.header,
                      style: {
                        color: '#FFF',
                        font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                      },
                    },
                    yAxis: [
                      {
                        // Primary yAxis
                        alternateGridColor: null,
                        minorTickInterval: null,
                        gridLineColor: 'rgba(255, 255, 255, .1)',
                        lineWidth: 0,
                        tickWidth: 0,
                        labels: {
                          style: {
                            color: '#999',
                            fontWeight: 'bold',
                          },
                        },
                        title: {
                          text: 'value',
                        },
                      },
                      {
                        // Secondary yAxis
                        alternateGridColor: null,
                        minorTickInterval: null,
                        gridLineColor: 'rgba(255, 255, 255, .1)',
                        lineWidth: 0,
                        tickWidth: 0,
                        labels: {
                          style: {
                            color: '#999',
                            fontWeight: 'bold',
                          },
                        },
                        title: {
                          text: 'value',
                        },
                        opposite: true,
                      },
                    ],
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
 