import React from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import exportFromJSON from 'export-from-json';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      (resolvedRef as any).current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export function NewSearchResults({ dataFromSearch }: { dataFromSearch: any }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'IMO',
        accessor: 'IMO', // accessor is the "key" in the data
      },
      {
        Header: 'Vessel name',
        accessor: 'Vessel', // accessor is the "key" in the data
      },
      {
        Header: 'Segment',
        accessor: 'ShipType',
      },
      {
        Header: 'Subsegment',
        accessor: 'ShipSize',
      },
      {
        Header: 'Built',
        accessor: 'DateOfBuild',
      },
      {
        Header: 'DWT',
        accessor: 'DeadWeight',
      },
      {
        Header: 'CBM',
        accessor: 'GasCapacity',
      },
      {
        Header: 'Ice class',
        accessor: 'IceClass',
      },
      {
        Header: 'W/scrubber',
        accessor: 'Scrubber',
      },
      {
        Header: 'Shipbuilder',
        accessor: 'Shipbuilder',
      },
      {
        Header: 'Reg. owner',
        accessor: 'RegisteredOwner',
      },
      {
        Header: 'Current status',
        accessor: 'CurrentStatus',
      },
      {
        Header: 'Status',
        accessor: 'ShipStatus',
      },
      {
        Header: 'Scrap date',
        accessor: 'DeathDate',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: dataFromSearch,
      initialState: { pageIndex: 0, pageSize: 40 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div className="desktop-export">
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className="desktop-export">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  const ExportSelectedData = () => {
    let data: any = selectedFlatRows.map((d) => d.original);
    data = data.map(function (obj: any) {
      return {
        IMO: obj.IMO,
        'Vessel name': obj.Vessel,
        Segment: obj.ShipType,
        Subsegment: obj.ShipSize,
        Built: obj.DateOfBuild,
        DWT: obj.DeadWeight,
        CBM: obj.GasCapacity,
        'Ice class': obj.IceClass,
        'W/scrubber': obj.Scrubber,
        Shipbuilder: obj.Shipbuilder,
        'Reg. owner': obj.RegisteredOwner,
        Status: obj.ShipStatus,
        'Current status': obj.CurrentStatus,
        'Scrap date': obj.DeathDate,
      };
    });
    exportFromJSON({
      data,
      fileName: 'fleet-download_selected',
      exportType: 'xls',
    });
  };
  const ExportAllData = () => {
    let data = dataFromSearch;
    data = data.map(function (obj: any) {
      return {
        IMO: obj.IMO,
        'Vessel name': obj.Vessel,
        Segment: obj.ShipType,
        Subsegment: obj.ShipSize,
        Built: obj.DateOfBuild,
        DWT: obj.DeadWeight,
        CBM: obj.GasCapacity,
        'Ice class': obj.IceClass,
        'W/scrubber': obj.Scrubber,
        Shipbuilder: obj.Shipbuilder,
        'Reg. owner': obj.RegisteredOwner,
        Status: obj.ShipStatus,
        'Current status': obj.CurrentStatus,
        'Scrap date': obj.DeathDate,
      };
    });
    exportFromJSON({ data, fileName: 'fleet-download_all', exportType: 'xls' });
  };

  return (
    <div>
      <div className="table-wrapper">
        <table {...getTableProps()} className="fl-table" id="bigTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{}}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (cell.column.id === 'comments') {
                      if (cell.value === '' || cell.value === null)
                        return <td key={index}></td>;
                      return (
                        <td
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title={cell.value}
                          key={index}
                        >
                          <i className="fas fa-mouse fa-lg"></i>
                        </td>
                      );
                    } else if (
                      cell.column.id === 'DateOfBuild' ||
                      cell.column.id === 'SalesDate'
                    ) {
                      if (cell.value === '' || cell.value === null)
                        return <td key={index}></td>;
                      const dateForm = new Date(cell.value);
                      return (
                        <td key={index}>{dateForm.toLocaleDateString('nb')}</td>
                      );
                    }

                    return (
                      <td
                        {...cell.getCellProps()}
                        key={`${i}-${index}-table`}
                        style={{}}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="af_code_pagination pt-3">
        <button
          className="btn btn-default btn-sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          className="btn btn-default btn-sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          className="btn btn-default btn-sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          className="btn btn-default btn-sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="desktop-export">
        <button
          className="btn btn-secondary"
          style={{ marginLeft: 5 }}
          onClick={ExportSelectedData}
        >
          Export Selected
        </button>
        <button
          className="btn btn-secondary"
          style={{ marginLeft: 5 }}
          onClick={ExportAllData}
        >
          Export All
        </button>
      </div>
      {/* <pre>
          <code>
            {JSON.stringify(
              {
                selectedRowIds: selectedRowIds,
                'selectedFlatRows[].original': selectedFlatRows.map(
                  d => d.original
                ),
              },
              null,
              2
            )}
          </code>
        </pre> */}
    </div>
  );
}
