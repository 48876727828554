import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Segment, SegmentMatch, getTitleForSegment } from '../state/segment';

export function SegmentChanger() {
  const navigate = useNavigate();
  const params = useParams();
  const locationObj = useLocation();

  const currentPath = locationObj?.pathname;
  const currentSegment = params?.segment;

  const handleSegmentChange = (event: String | null) => {
    const segmentWithoutSpaces = event?.split(' ').join('').toLowerCase();

    const newPathName = currentPath
      .split('/')
      .map((word: string) =>
        word === currentSegment ? segmentWithoutSpaces : word
      )
      .join('/');

    navigate(newPathName, { replace: true });
  };

  return (
    <div>
      <DropdownButton
        // menuAlign="right"
        title={getTitleForSegment(
          params.segment ? params.segment : SegmentMatch.DryCargo
        )}
        onSelect={handleSegmentChange}
        variant={'dark'}
      >
        <Dropdown.Item eventKey={Segment.DryCargo}>
          {Segment.DryCargo}
        </Dropdown.Item>
        <Dropdown.Item eventKey={Segment.Tankers}>
          {Segment.Tankers}
        </Dropdown.Item>
        <Dropdown.Item eventKey={Segment.LPG}>{Segment.LPG}</Dropdown.Item>
        <Dropdown.Item eventKey={Segment.LNG}>{Segment.LNG}</Dropdown.Item>
      </DropdownButton>
    </div>
  );
}
