import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { fetchWithSession } from './fetchWithSession';
import { FFAResponse } from './FFATables';

export function FFAFetch({ segment, unit }: { segment: String; unit: String }) {
  const [data, setData] = useState<FFAResponse[]>();

  async function getFuelData() {
    const response = await fetchWithSession(`/baltic-ffa`);
    return await response.json();
  }

  useEffect(() => {
    getFuelData()
      .then((data) => {
        setData(data.response);
      })
      .catch((error) => console.warn('error', error));
  }, []);

  function colorCell(content: number, decimals: number = 2): JSX.Element {
    return content > 0 ? (
      <td style={{ color: 'green' }}>
        {'+' +
          new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          }).format(content)}
      </td>
    ) : content < 0 ? (
      <td style={{ color: 'red' }}>
        {new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(content)}
      </td>
    ) : (
      <td>-</td>
    );
  }
  return (
    <Container fluid>
      <Row xs={1} md={2} lg={2}>
        {data &&
          data.map(
            (e: FFAResponse) =>
              e.ffa &&
              e.segment === segment &&
              e.ffa.map((ffaObj: any, key: any) => (
                <Col key={key}>
                  <h3 style={{ fontSize: 'large' }}>
                    {ffaObj.route}
                    <span style={{ float: 'right', color: 'black' }}>
                      {ffaObj.data[0].date &&
                        new Date(ffaObj.data[0].date).toDateString()}
                    </span>
                  </h3>
                  <Table
                    className="rate-table bunker-future-table"
                    striped
                    bordered
                    hover
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>Forward Curve Name</th>
                        <th>Period</th>
                        <th>Value {ffaObj.unit ? `(${ffaObj.unit})` : ''}</th>
                        <th>Change</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ffaObj.data &&
                        ffaObj.data.map((ffaDataObj: any, k: any) => (
                          <tr key={k}>
                            <td>
                              {ffaDataObj.fwd_curve_name
                                ? ffaDataObj.fwd_curve_name
                                : '-'}
                            </td>
                            <td>
                              {ffaDataObj.period ? ffaDataObj.period : '-'}
                            </td>
                            <td>
                              {ffaDataObj.value
                                ? `${new Intl.NumberFormat('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(ffaDataObj.value)} ${ffaObj?.unit}`
                                : '-'}
                            </td>
                            {ffaDataObj.change ? (
                              colorCell(ffaDataObj.change)
                            ) : (
                              <td>-</td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
              ))
          )}
      </Row>
    </Container>
  );
}
