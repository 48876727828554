import {
  useLocation,
  //Redirect,
  BrowserRouter as BrowserRouterOriginal,
} from 'react-router-dom';
import { useEffect } from 'react';

// todo: update children: any to React.ReactNode
export function BrowserRouter({ children }: { children?: any }) {
  return (
    <BrowserRouterOriginal //history={history}
    >
      <ScrollToTop />
      {children}
    </BrowserRouterOriginal>
  );
}
// Remove or customize if you need more advanced scroll behavior
// and don't want to always scroll to top when location.pathname changes.
function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
