import { useState } from 'react';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import { getAPIPath } from '../getAPIPath';

export function LoginPage(): JSX.Element {
  const [isPosting, setIsPosting] = useState<boolean>(false);

  return (
    <div>
      <>
        <Container className="d-flex vh-100">
          <Row className="align-self-center">
            <Col>
              <h1 style={{ fontFamily: 'calibri' }}>Welcome to FearnPortal</h1>
              <p style={{ color: 'black', fontFamily: 'calibri' }}>
                Click the button below to sign in with Office 365.
              </p>
              {isPosting && (
                <div>
                  <Spinner animation="border" variant="secondary" />
                </div>
              )}
              <form noValidate></form>
              <p>
                <Button
                  style={{ fontFamily: 'calibri' }}
                  variant="secondary"
                  onClick={() => {
                    setIsPosting(true);
                    window.location.href = `${getAPIPath()}/auth/login`;
                  }}
                >
                  Sign in
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </>
    </div>
  );
}
