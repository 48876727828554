import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';

import { useEffect, useState } from 'react';
HighchartsExporting(Highcharts);
interface Props {
  data: any;
}
const highchartsOptions = {
  exporting: {
    fetchOptions: {
      headers: {
        referer: 'https://fearnportal.com',
      },
    },
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'separator', 'downloadXLS'],
        theme: {
          fill: '#142E3D',
        },
        symbolFill: '#000000',
      },
    },
    chartOptions: {
      title: {
        style: {
          color: 'black', // black title
        },
      },
      subtitle: {
        text: '', // removes "click and drag" subtitle from exported chart
        style: {
          color: 'black',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            color: 'black',
          },
        },
        column: {
          dataLabels: {
            enabled: true,
            color: 'black',
          },
        },
      },
      chart: {
        backgroundColor: {
          linearGradient: [0, 0, 0, 400],
          stops: [
            [0, '#F0F0F0'], // nice light background
            [1, '#FFFFFF'],
          ],
        },
        borderWidth: 0,
        borderRadius: 15,
        plotBackgroundColor: null,
        plotShadow: false,
        plotBorderWidth: 0,
      },
      legend: {
        itemStyle: {
          color: '#CCC',
        },
        itemHoverStyle: {
          color: '#FFF',
        },
        itemHiddenStyle: {
          color: '#333',
        },
      },
    },
  },
  chart: {
    type: 'spline',
    zoomType: 'x',
    //   backgroundColor: {
    //     linearGradient: [0, 0, 0, 500],
    //     stops: [
    //         [0, "#142E3D"],
    //         [1, "#3b454b"],
    //     ],
    // },
    backgroundColor: '#142E3D',
    borderWidth: 0,
    borderRadius: 0,
    plotBackgroundColor: null,
    plotShadow: false,
    plotBorderWidth: 0,
  },
  // fixing credits:
  credits: {
    style: {
      fontSize: '15px',
    },
    enabled: true,
    text: '© ' + new Date().getFullYear() + ' Fearnleys',
    href: 'https://www.fearnleys.com',
    position: {
      align: 'left',
      verticalAlign: 'top',
      x: 100,
      y: 100,
    },
  },

  // Dark theme:
  colors: [
    '#5787b2',
    '#95D2CA',
    '#c1c0c0',
    '#8A84FF',
    '#7FC2FB',
    '#206273',
    '#D46C75',
    '#bfbfbf',
    '#997D87',
    '#0000A6',
    '#63FFAC',
    '#FF4A46',
    '#5A0007',
    '#809693',
    '#FEFFE6',
    '#1B4400',
    '#4FC601',
    '#3B5DFF',
    '#4A3B53',
    '#FF2F80',
    '#61615A',
    '#BA0900',
    '#6B7900',
    '#00C2A0',
    '#FFAA92',
    '#FF90C9',
    '#B903AA',
    '#D16100',
    '#DDEFFF',
    '#000035',
    '#7B4F4B',
    '#A1C299',
  ],

  xAxis: {
    gridLineWidth: 0,
    lineColor: '#999',
    tickColor: '#999',
    labels: {
      style: {
        color: '#999',
        fontWeight: 'bold',
      },
    },
    type: 'datetime',
    title: {
      style: {
        color: '#AAA',
        font: 'bold 12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  },
  legend: {
    itemStyle: {
      color: '#CCC',
      fontSize: '9px',
      font: '9pt Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#333',
    },
  },

  labels: {
    style: {
      color: '#CCC',
    },
  },
  tooltip: {
    backgroundColor: {
      linearGradient: [0, 0, 0, 50],
      stops: [
        [0, 'rgba(96, 96, 96, .8)'],
        [1, 'rgba(16, 16, 16, .8)'],
      ],
    },
    borderWidth: 0,
    style: {
      color: '#FFF',
    },
  },

  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}',
        color: '#ffffff',
      },
    },
    column: {
      borderWidth: 0,
      pointPadding: 0,
      stacking: 'stream',
      dataLabels: {
        enabled: false,
      },
    },
    line: {
      dataLabels: {
        color: '#CCC',
      },
      marker: {
        lineColor: '#333',
      },
    },
    spline: {
      marker: {
        lineColor: '#333',
      },
    },
    scatter: {
      marker: {
        lineColor: '#333',
      },
    },
  },

  toolbar: {
    itemStyle: {
      color: '#CCC',
    },
  },
};
export const FuelChart = (props: Props) => {
  const [spreadValuesSgp, setSpreadValuesSgp] = useState<number[][]>([]);
  const [spreadValuesRtm, setSpreadValuesRtm] = useState<number[][]>([]);
  const [spreadValuesFuj, setSpreadValuesFuj] = useState<number[][]>([]);
  let fuelArraySgpFuj = [];
  let fuelArrayRtmHou = [];
  let fuelArrayHsfoVlsfoSpread = [];

  fuelArraySgpFuj.push({
    data: props.data.singapore.vlsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'SGP VLSFO',
    color: '#5787b2',
  });
  fuelArraySgpFuj.push({
    data: props.data.fujairah.vlsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'FUJ VLSFO',
    color: '#95D2CA',
  });
  fuelArraySgpFuj.push({
    data: props.data.singapore.lsmgo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'SGP MGO',
    color: '#D46C75',
  });
  fuelArraySgpFuj.push({
    data: props.data.fujairah.lsmgo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'FUJ MGO',
    color: '#c1c0c0',
  });
  fuelArraySgpFuj.push({
    data: props.data.singapore.hsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'SGP HSFO',
    color: '#7FC2FB',
  });
  fuelArraySgpFuj.push({
    data: props.data.fujairah.hsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'FUJ HSFO',
    color: '#206273',
  });

  fuelArrayRtmHou.push({
    data: props.data.rotterdam.vlsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'RTM VLSFO',
    color: '#5787b2',
  });
  fuelArrayRtmHou.push({
    data: props.data.houston.vlsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'HOU VLSFO',
    color: '#95D2CA',
  });
  fuelArrayRtmHou.push({
    data: props.data.rotterdam.lsmgo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'RTM MGO',
    color: '#D46C75',
  });
  fuelArrayRtmHou.push({
    data: props.data.houston.lsmgo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'HOU MGO',
    color: '#c1c0c0',
  });
  fuelArrayRtmHou.push({
    data: props.data.rotterdam.hsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'RTM HSFO',
    color: '#7FC2FB',
  });
  fuelArrayRtmHou.push({
    data: props.data.houston.hsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'HOU HSFO',
    color: '#206273',
  });

  useEffect(() => {
    const azureFunction = async (): Promise<any> => {
      const response = await fetch(
        'https://pfearnapiwe.azurewebsites.net/api/fearnpulse_TS_api?code=maE3//XRWkGkWJEOpS520l9FSYPKXnynrYltgrntvd7SVF3/2spF8w==&id=7520_7557_7631_7668_7964_8001'
      );
      const body = await response.json();
      return body;
    };
    let apiReturn = azureFunction();
    let p = Promise.resolve(apiReturn);
    let tempSgp: number[][] = [];
    let tempRtm: number[][] = [];
    let tempFuj: number[][] = [];

    p.then(function (v) {
      for (let i = 0; i < v.data.length; i++) {
        if (i > v.data.length - 365) {
          v.data[i][0] &&
            v.data[i][1] &&
            tempSgp.push([v.index[i], v.data[i][1] - v.data[i][0]]);
          v.data[i][2] &&
            v.data[i][3] &&
            tempRtm.push([v.index[i], v.data[i][3] - v.data[i][2]]);
          v.data[i][4] &&
            v.data[i][5] &&
            tempFuj.push([v.index[i], v.data[i][5] - v.data[i][4]]);
        }
      }
      setSpreadValuesSgp(tempSgp);
      setSpreadValuesRtm(tempRtm);
      setSpreadValuesFuj(tempFuj);
    }).catch((error) => console.warn('error', error));
  }, []);

  fuelArrayHsfoVlsfoSpread.push({
    data: spreadValuesSgp,
    name: 'SGP',
    color: '#D46C75',
  });
  fuelArrayHsfoVlsfoSpread.push({
    data: spreadValuesRtm,
    name: 'RTM',
    color: '#7FC2FB',
  });
  fuelArrayHsfoVlsfoSpread.push({
    data: spreadValuesFuj,
    name: 'FUJ',
    color: '#c1c0c0',
  });

  const customOptionsSgpFuj = {
    ...highchartsOptions,
    series: fuelArraySgpFuj,
    title: {
      text: 'Singapore/Fujairah',
      style: {
        color: '#FFF',
        font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  };
  const customOptionsRtmHou = {
    ...highchartsOptions,
    series: fuelArrayRtmHou,
    title: {
      text: 'Rotterdam/Houston',
      style: {
        color: '#FFF',
        font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  };
  const customOptionsHsfoVlsfoSpread = {
    ...highchartsOptions,
    series: fuelArrayHsfoVlsfoSpread,
    title: {
      text: 'HSFO/VLSFO spread',
      style: {
        color: '#FFF',
        font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  };

  return (
    <>
      <div style={{ marginBottom: '21px' }}>
        <HighchartsReact
          containerProps={{ style: { height: 300 } }}
          highcharts={Highcharts}
          options={customOptionsSgpFuj}
        />
      </div>
      <div style={{ marginBottom: '21px' }}>
        <HighchartsReact
          containerProps={{ style: { height: 300 } }}
          highcharts={Highcharts}
          options={customOptionsRtmHou}
        />
      </div>
      <div style={{ marginBottom: '21px' }}>
        <HighchartsReact
          containerProps={{ style: { height: 300 } }}
          highcharts={Highcharts}
          options={customOptionsHsfoVlsfoSpread}
        />
      </div>
    </>
  );
};
