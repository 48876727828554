import React from 'react';
import { RangeFormState } from '../state/searchVessel';

interface Props { 
  value: RangeFormState, 
  name: string, 
  label: string, 
  stateSetter: (name: string, value: any) => void
}

const FOSBSRange = (props: Props) => {

  return <>
    <div className="row align-items-center">
      <div className="col-sm-4">
        <label className="col-form-label">{props.label}</label>
      </div>
      <div className="col">
        <input type="text" className="form-control form-select-sm" placeholder="From" value={props.value.from} onChange={(e) => { props.stateSetter(props.name, { ...props.value, from: e.target.value }) }} />
      </div>
      <div className="col">
        <input type="text" className="form-control form-select-sm" placeholder="To" value={props.value.to} onChange={(e) => { props.stateSetter(props.name, { ...props.value, to: e.target.value }) }} />
      </div>
    </div>
  </>;
}

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}


export default React.memo(FOSBSRange, areEqual);
