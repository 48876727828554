export function scriptTagTicker2_DryCargo() {
  const scriptTagTicker2 = document.createElement('script');
  scriptTagTicker2.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
  scriptTagTicker2.async = true;
  scriptTagTicker2.innerHTML = `
          {
    "colorTheme": "dark",
    "dateRange": "12M",
    "showChart": false,
    "locale": "en",
    "width": "100%",
    "height": "462",
    "largeChartUrl": "",
    "isTransparent": false,
    "showSymbolLogo": true,
    "tabs": [
    {
     "title": "Commodities",
     "symbols": [
       {
         "s": "TVC:UKOIL",
         "d": "Brent Crude Oil"
       },
       {
         "s": "COMEX:HG1!",
         "d": "Copper"
       },
       {
         "s": "COMEX:TIO1!",
         "d": "Iron Ore"
       },
       {
         "s": "NYMEX:RLS1!",
         "d": "Coal"
       },
       {
         "s": "COMEX:EHR1!",
         "d": "Steel"
       },
       {
         "s": "LSE:SOYU",
         "d": "Soybeans"
       }
     ],
     "originalTitle": "Commodities"
    },
    {
     "title": "Indices",
     "symbols": [
       {
         "s": "INDEX:OBX",
         "d": "Oslo Børs"
       },
       {
         "s": "CURRENCYCOM:US500",
         "d": "S&P 500"
       },
       {
         "s": "CURRENCYCOM:US100",
         "d": "Nasdaq"
       },
       {
         "s": "XETR:DAX",
         "d": "DAX"
       },
       {
         "s": "CAPITALCOM:UK100",
         "d": "FTSE 100"
       },
       {
         "s": "OTC:ISMUF",
         "d": "MSCI China"
       },
       {
         "s": "HSI:HSI",
         "d": "Hang Seng"
       },
       {
         "s": "index:sti",
         "d": "Straits Times Index"
       },
       {
         "s": "CAPITALCOM:J225",
         "d": "Nikkei 225"
       }
     ],
     "originalTitle": "Bonds"
    },
    {
     "title": "Stocks",
     "symbols": [

       {
         "s": "NYSE:GNK",
         "d": "Genco"
       },
       {
         "s": "NASDAQ:GOGL",
         "d": "Golden Ocean"
       },
       {
         "s": "NASDAQ:SBLK",
         "d": "Star Bulk"
       },
       {
         "s": "NYSE:VALE",
         "d": "Vale"
       },
       {
         "s": "ASX:RIO",
         "d": "Rio Tinto"
       },
       {
         "s": "BSE:COALINDIA",
         "d": "Coal India"
       },
       {
         "s": "OTC:GLNCY",
         "d": "Glencore"
       },
       {
         "s": "NYSE:MT",
         "d": "ArcelorMittal"
       },
       {
         "s": "OTC:NGLOY",
         "d": "AngloAmerican"
       }
     ],
     "originalTitle": "Forex"
    }
    ]
    }
          `;
  document
    .getElementsByClassName('tradingview-widget-container-6')[0]
    .append(scriptTagTicker2);
}

export function scriptTagTicker_DryCargo() {
  const scriptTagTicker = document.createElement('script');
  scriptTagTicker.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
  scriptTagTicker.async = true;
  scriptTagTicker.innerHTML = `
        {
          "symbols": [
            {
              "title": "Brent",
              "proName": "TVC:UKOIL"
            },
            {
              "title": "USD/NOK",
              "proName": "FX_IDC:USDNOK"
            },
            {
              "title": "USD/EUR",
              "proName": "FX_IDC:USDEUR"
            },
            {
              "title": "USD/KRW",
              "proName": "FX_IDC:USDKRW"
            },
            {
              "title": "USD/JPY",
              "proName": "OANDA:USDJPY"
            }
          ],
          "colorTheme": "dark",
          "isTransparent": false,
          "locale": "en"
        }
        `;
  document
    .getElementsByClassName('tradingview-widget-container-5')[0]
    .append(scriptTagTicker);
}

export function ScriptTag4_DryCargo() {
  const scriptTag3 = document.createElement('script');
  scriptTag3.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag3.async = true;
  scriptTag3.innerHTML = `
          {
            "symbol": "NASDAQ:CME",
     "width": "100%",
     "height": "220",
     "locale": "en",
     "dateRange": "12M",
     "colorTheme": "dark",
     "trendLineColor": "#37a6ef",
     "underLineColor": "rgba(55, 166, 239, 0.15)",
     "isTransparent": false,
     "autosize": false,
     "largeChartUrl": ""
          }
          `;
  document
    .getElementsByClassName('tradingview-widget-container-4')[0]
    .append(scriptTag3);
}

export function ScriptTag3_DryCargo() {
  const scriptTag2 = document.createElement('script');
  scriptTag2.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag2.async = true;
  scriptTag2.innerHTML = `
        {
          "symbol": "INDEX:DXY",
    "width": "100%",
    "height": "220",
    "locale": "en",
    "dateRange": "12M",
    "colorTheme": "dark",
    "trendLineColor": "#37a6ef",
    "underLineColor": "rgba(55, 166, 239, 0.15)",
    "isTransparent": false,
    "autosize": false,
    "largeChartUrl": ""
        }
        `;
  document
    .getElementsByClassName('tradingview-widget-container-3')[0]
    .append(scriptTag2);
}

export function ScriptTag1_DryCargo() {
  const scriptTag1 = document.createElement('script');
  scriptTag1.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag1.async = true;
  scriptTag1.innerHTML = `
        {
          "symbol": "TVC:USOIL",
    "width": "100%",
    "height": "220",
    "locale": "en",
    "dateRange": "12M",
    "colorTheme": "dark",
    "trendLineColor": "#37a6ef",
    "underLineColor": "rgba(55, 166, 239, 0.15)",
    "isTransparent": false,
    "autosize": false,
    "largeChartUrl": ""
        }
        `;
  document
    .getElementsByClassName('tradingview-widget-container-2')[0]
    .append(scriptTag1);
}

export function ScriptTag_DryCargo() {
  const scriptTag = document.createElement('script');
  scriptTag.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag.async = true;
  scriptTag.innerHTML = `
      {
        "symbol": "TVC:UKOIL",
    "width": "100%",
    "height": "220",
    "locale": "en",
    "dateRange": "12M",
    "colorTheme": "dark",
    "trendLineColor": "#37a6ef",
    "underLineColor": "rgba(55, 166, 239, 0.15)",
    "isTransparent": false,
    "autosize": false,
    "largeChartUrl": ""
      }
      `;
  document
    .getElementsByClassName('tradingview-widget-container-1')[0]
    .append(scriptTag);
}
