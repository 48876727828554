import { getAPIPath } from 'getAPIPath';
import { customizedToast } from './customizedToast';
import { MailList } from './Mailer';

export async function handleSubmitMail(
  selectedFile: any,
  senderEmail: string,
  senderName: string,
  subject: string,
  text: string,
  selectedMailList: MailList | undefined,
  setIsSentEmail: (value: boolean) => void // React.Dispatch<React.SetStateAction<boolean>>
): Promise<boolean> {
  //Bruker empty string som false siden jeg sender det som url-param.

  let formData = new FormData();

  if (selectedFile) {
    formData.append('file', selectedFile as any);
  }

  const hasAttachment = formData.has('file') === true;

  const url = hasAttachment
    ? `${getAPIPath()}/sendEmailWithAttachment/?senderEmail=${senderEmail}&senderName=${senderName}&subject=${subject}&text=${text}&selectedMailList=${selectedMailList}`
    : `${getAPIPath()}/sendEmail/?senderEmail=${senderEmail}&senderName=${senderName}&subject=${subject}&text=${text}&selectedMailList=${selectedMailList}`;

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    mode: 'cors',
    credentials: 'include',
  });

  if (response.status === 200) {
    customizedToast('mail sent!', '✅');
    setIsSentEmail(true);
    return true;
  } else {
    customizedToast('mail not sent!', '❌');
    setIsSentEmail(false);
    return false;
  }
}
