import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { fetchWithSession } from 'components/fetchWithSession';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc); // import plugin
dayjs.extend(duration);

interface NewsResponse {
  author: string;
  categories: string[];
  content: string;
  contentSnippet: string;
  creator: string;
  guid: string;
  isoDate: Date;
  link: string;
  pubDate: Date;
  title: string;
}
export default function News() {
  const [rss, setRss] = useState<NewsResponse[]>([]);
  const [dataLoaded, setDataLoaded] = useState(true);
  async function getAllNews() {
    const response = await fetchWithSession(`/tradewinds-news`);
    return await response.json();
  }
  const fetchAllNews = () => {
    setDataLoaded(false);
    getAllNews()
      .then((rss: any) => {
        setDataLoaded(true);
        setRss(rss);
      })
      .catch((error) => console.warn('error', error));
  };

  useEffect(() => {
    fetchAllNews(); // fetching immediately at first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => fetchAllNews(), 4 * 60 * 1000); // fetching news after every 4 minutes
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeParse = (isoDate: Date): string => {
    const now = new Date();
    let dateObj = new Date(isoDate);
    const durationDays =
      dayjs
        .duration(now.valueOf() - dateObj.valueOf(), 'millisecond')
        .asMilliseconds() / 86400000;
    const durationHours =
      dayjs
        .duration(now.valueOf() - dateObj.valueOf(), 'millisecond')
        .asMilliseconds() / 3600000;
    const durationMinutes = dayjs
      .utc(
        dayjs
          .duration(now.valueOf() - dateObj.valueOf(), 'millisecond')
          .asMilliseconds()
      )
      .format('mm');
    if (durationHours < 23) {
      if (durationHours < 1) {
        return durationMinutes + 'm';
      }
      if (durationHours < 4) {
        return (
          Math.round(durationHours).toString() + 'h' + durationMinutes + 'm'
        );
      } else {
        return Math.round(durationHours).toString() + 'h';
      }
    } else {
      return Math.round(durationDays).toString() + 'd';
    }
  };
  return (
    <>
      <div
        className="news-header"
        style={{ overflowY: 'scroll', backgroundColor: '#292F33' }}
      >
        <img
          src="https://static-global.nhst.tech/resources/gfx/tradewinds/logo.svg"
          alt=""
          style={{ filter: 'invert(1)', padding: '5px' }}
        ></img>
      </div>
      <div className="news-container">
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: '840px',
            backgroundColor: '#292F33',
          }}
        >
          {!dataLoaded && <Spinner animation="border" />}
          {dataLoaded &&
            rss.map((e: NewsResponse, k) => (
              <div className="news-element" key={k}>
                <h6
                  style={{
                    color: '#F5F5F5',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                    padding: '2px',
                  }}
                >
                  {e.title}
                </h6>
                <a
                  href={e.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: '14px', color: '#F5F5F5', padding: '2px' }}
                >
                  {e.contentSnippet}
                </a>
                <p style={{ textAlign: 'right' }}>
                  <small title={new Date(e.isoDate).toLocaleString()}>
                    {handleTimeParse(e.isoDate)}
                  </small>
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
