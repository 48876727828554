import { SegmentMatch } from '../../state/segment';

export function getTwitterFeedUrlFunc(segment: string): string | undefined {
  if (segment === SegmentMatch.Tankers) {
    return 'https://twitter.com/BernhardAndres/lists/tanker-list-11065?ref_src=twsrc%5Etfw'; // Tankers specific feed  <a class="twitter-timeline" href="https://twitter.com/BernhardAndres/lists/tanker-list-11065?ref_src=twsrc%5Etfw">A Twitter List by BernhardAndres</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  } else if (segment === SegmentMatch.LNG) {
    return 'https://twitter.com/BernhardAndres/lists/lng-list-13247?ref_src=twsrc%5Etfw'; // LNG specific feed <a class="twitter-timeline" href="https://twitter.com/BernhardAndres/lists/lng-list-13247?ref_src=twsrc%5Etfw">A Twitter List by BernhardAndres</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  } else if (segment === SegmentMatch.LPG) {
    return 'https://twitter.com/BernhardAndres/lists/lpg-list-19674?ref_src=twsrc%5Etfw'; // LPG specific feed <a class="twitter-timeline" href="https://twitter.com/BernhardAndres/lists/lpg-list-19674?ref_src=twsrc%5Etfw">A Twitter List by BernhardAndres</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  } else if (segment === SegmentMatch.DryCargo) {
    return 'https://twitter.com/BernhardAndres/lists/webapp-list?ref_src=twsrc%5Etfw';
  }
}

export function spawnTwitter(segment: string) {
  try {
    const twitterUrl: string | undefined = getTwitterFeedUrlFunc(segment);

    if (twitterUrl !== undefined) {
      const twitterElem = document.createElement('a');
      twitterElem.className = 'twitter-timeline';
      twitterElem.setAttribute('data-height', '938');
      twitterElem.setAttribute('data-theme', 'dark');
      twitterElem.setAttribute('href', twitterUrl as string);
      twitterElem.setAttribute('data-chrome', 'nofooter noheader noscrollbar');

      document.getElementsByClassName('twitter-mother')[0].append(twitterElem);

      // Create the associated Twitter script tag
      const scriptTwitter = document.createElement('script');
      scriptTwitter.src = 'https://platform.twitter.com/widgets.js';
      scriptTwitter.async = true;

      document
        .getElementsByClassName('twitter-timeline')[0]
        .append(scriptTwitter);
    }
  } catch (err) {
    console.error('unable to spawn twitter');
    console.error(err);
  }
}
