import { useRef, useState } from 'react';
import { CloseButton, Overlay, Popover } from 'react-bootstrap';

export default function Footer() {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <div className="mt-5 pt-5 pb-5 footer navbar-gradient">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xs-12 about-company">
            <h4 className="fw-light">Fearnleys</h4>
            <p className="pr-5 text-white-50">
              Servicing the shipping industry since 1869
            </p>
            <p>
              <a
                href="https://www.linkedin.com/company/astrup-fearnley-as/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </p>
          </div>
          <div className="col-lg-3 col-xs-12 links">
            <h4 className="mt-lg-0 mt-sm-3 fw-light">Links</h4>
            <ul className="m-0 p-0">
              <li>
                -{' '}
                <a
                  href="https://fearnleys.com/services/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Services
                </a>
              </li>
              <li>
                -{' '}
                <a
                  href="https://fearnleys.com/research/"
                  target="_blank"
                  rel="noreferrer"
                >
                  FearnResearch
                </a>
              </li>
              <li>
                -{' '}
                <a
                  href="https://fearnpulse.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Weekly Report
                </a>
              </li>
              <li>
                -{' '}
                <a
                  href="https://fearnleys.com/story/"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </li>
              <li>
                -{' '}
                <a
                  href="https://fearnleys.com/contacts-team/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contacts
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-xs-12 location">
            <h4 className="mt-lg-0 mt-sm-4 fw-light">Location</h4>
            <p className="mb-0">Dronning Eufemias gate 8</p>
            <p className="mb-0">0191 Oslo | Norway</p>
            <p className="mb-0 text-white">
              <i className="fa fa-phone mr-3"></i>
              <a href="tel:+4722936000" className="text-white">
                {' '}
                +47 22 93 60 00
              </a>
            </p>
            <div ref={ref}>
              <button
                style={{ background: 'transparent', border: 'none' }}
                className="mb-0 text-white"
                onClick={handleClick}
              >
                Guest WiFi
              </button>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">
                    DEG8 WiFi{' '}
                    <CloseButton
                      style={{ float: 'right' }}
                      onClick={handleClick}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    Connect to <strong>afguest</strong>, and you will be
                    directed to a web page.
                    <br />
                    Enter your phone number (w/ country code), and you will
                    receive an SMS with the code needed to access the guest
                    network.
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col copyright">
            <p>
              <small className="text-white-50">
                © {new Date().getFullYear()}. All Rights Reserved.
              </small>
            </p>
            <img
              src={
                'https://ik.imagekit.io/afcode/logo/tr:w-200/af_code_inverted_JaLuJT7Fy.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652883070762'
              } //"http://rigwebninja.azurewebsites.net/foSLive/img/af-1.svg"
              alt="" //height="30"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
