import { AnalyticsProvider } from 'use-analytics';
import analytics from 'util/analytics';
import './App.css';
import { Root } from './Root';

export default function App() {
  return (
    <AnalyticsProvider instance={analytics}>
      <Root />
    </AnalyticsProvider>
  );
}
