// export function MakeScriptTag2_LPG() {
//   const ref: { current: HTMLDivElement | null } = createRef();

//   useEffect(() => {
//     let refValue: any;

//     if (ref.current) {
//       const scriptTag2 = document.createElement('script');
//       scriptTag2.src =
//         'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
//       scriptTag2.async = true;
//       scriptTag2.innerHTML = `
//           {
//             "symbol": "INDEX:DXY",
//       "width": "100%",
//       "height": "220",
//       "locale": "en",
//       "dateRange": "12M",
//       "colorTheme": "dark",
//       "trendLineColor": "#37a6ef",
//       "underLineColor": "rgba(55, 166, 239, 0.15)",
//       "isTransparent": false,
//       "autosize": false,
//       "largeChartUrl": ""
//           }
//           `;

//       ref.current.appendChild(scriptTag2);
//       refValue = ref.current;
//     }

//     return () => {
//       if (refValue) {
//         while (refValue.firstChild) {
//           refValue.removeChild(refValue.firstChild);
//         }
//       }
//     };
//   }, [ref]);

//   //return scriptTag2;
//   return <div ref={ref} />;
// }

export function MakeScriptTag2_LPG() {
  const scriptTag2 = document.createElement('script');
  scriptTag2.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag2.async = true;
  scriptTag2.innerHTML = `
          {
            "symbol": "INDEX:DXY",
      "width": "100%",
      "height": "220",
      "locale": "en",
      "dateRange": "12M",
      "colorTheme": "dark",
      "trendLineColor": "#37a6ef",
      "underLineColor": "rgba(55, 166, 239, 0.15)",
      "isTransparent": false,
      "autosize": false,
      "largeChartUrl": ""
          }
          `;
  return scriptTag2;
}
