import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';

import { Tab, Table, Tabs } from 'react-bootstrap';
import dayjs from 'dayjs';
HighchartsExporting(Highcharts);
interface Props {
  port: string;
  data: any;
}
const highchartsOptions = {
  exporting: {
    fetchOptions: {
      headers: {
        referer: 'https://fearnportal.com',
      },
    },
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'separator', 'downloadXLS'],
        theme: {
          fill: '#142E3D',
        },
        symbolFill: '#000000',
      },
    },
    chartOptions: {
      title: {
        style: {
          color: 'black', // black title
        },
      },
      subtitle: {
        text: '', // removes "click and drag" subtitle from exported chart
        style: {
          color: 'black',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            color: 'black',
          },
        },
        column: {
          dataLabels: {
            enabled: true,
            color: 'black',
          },
        },
      },
      chart: {
        backgroundColor: {
          linearGradient: [0, 0, 0, 400],
          stops: [
            [0, '#F0F0F0'], // nice light background
            [1, '#FFFFFF'],
          ],
        },
        borderWidth: 0,
        borderRadius: 15,
        plotBackgroundColor: null,
        plotShadow: false,
        plotBorderWidth: 0,
      },
      legend: {
        itemStyle: {
          color: '#CCC',
        },
        itemHoverStyle: {
          color: '#FFF',
        },
        itemHiddenStyle: {
          color: '#333',
        },
      },
    },
  },
  chart: {
    type: 'spline',
    zoomType: 'x',
    //   backgroundColor: {
    //     linearGradient: [0, 0, 0, 500],
    //     stops: [
    //         [0, "#142E3D"],
    //         [1, "#3b454b"],
    //     ],
    // },
    backgroundColor: '#142E3D',
    borderWidth: 0,
    borderRadius: 0,
    plotBackgroundColor: null,
    plotShadow: false,
    plotBorderWidth: 0,
  },
  // fixing credits:
  credits: {
    style: {
      fontSize: '15px',
    },
    enabled: true,
    text: '© ' + new Date().getFullYear() + ' Fearnleys',
    href: 'https://www.fearnleys.com',
    position: {
      align: 'left',
      verticalAlign: 'top',
      x: 100,
      y: 100,
    },
  },

  // Dark theme:
  colors: [
    '#5787b2',
    '#95D2CA',
    '#c1c0c0',
    '#8A84FF',
    '#7FC2FB',
    '#206273',
    '#D46C75',
    '#bfbfbf',
    '#997D87',
    '#0000A6',
    '#63FFAC',
    '#FF4A46',
    '#5A0007',
    '#809693',
    '#FEFFE6',
    '#1B4400',
    '#4FC601',
    '#3B5DFF',
    '#4A3B53',
    '#FF2F80',
    '#61615A',
    '#BA0900',
    '#6B7900',
    '#00C2A0',
    '#FFAA92',
    '#FF90C9',
    '#B903AA',
    '#D16100',
    '#DDEFFF',
    '#000035',
    '#7B4F4B',
    '#A1C299',
  ],

  xAxis: {
    gridLineWidth: 0,
    lineColor: '#999',
    tickColor: '#999',
    labels: {
      style: {
        color: '#999',
        fontWeight: 'bold',
      },
    },
    type: 'datetime',
    title: {
      style: {
        color: '#AAA',
        font: 'bold 12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  },
  legend: {
    itemStyle: {
      color: '#CCC',
      fontSize: '9px',
      font: '9pt Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#333',
    },
  },

  labels: {
    style: {
      color: '#CCC',
    },
  },
  tooltip: {
    backgroundColor: {
      linearGradient: [0, 0, 0, 50],
      stops: [
        [0, 'rgba(96, 96, 96, .8)'],
        [1, 'rgba(16, 16, 16, .8)'],
      ],
    },
    borderWidth: 0,
    style: {
      color: '#FFF',
    },
  },

  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}',
        color: '#ffffff',
      },
    },
    column: {
      borderWidth: 0,
      pointPadding: 0,
      stacking: 'stream',
      dataLabels: {
        enabled: false,
      },
    },
    line: {
      dataLabels: {
        color: '#CCC',
      },
      marker: {
        lineColor: '#333',
      },
    },
    spline: {
      marker: {
        lineColor: '#333',
      },
    },
    scatter: {
      marker: {
        lineColor: '#333',
      },
    },
  },

  toolbar: {
    itemStyle: {
      color: '#CCC',
    },
  },
};
export const FuelModal = (props: Props) => {
  let historyArray = [];
  let futuresArray = [];
  historyArray.push({
    data: props.data.vlsfo.history.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'VLSFO',
    color: '#5787b2',
  });
  futuresArray.push({
    data: props.data.vlsfo.futures.map((e: any) => [
      new Date(e[0]).getTime(),
      e[1],
    ]),
    name: 'VLSFO',
    color: '#5787b2',
  });
  if (props.data.lsmgo) {
    historyArray.push({
      data: props.data.lsmgo.history.map((e: any) => [
        new Date(e[0]).getTime(),
        e[1],
      ]),
      name: 'MGO',
      color: '#95D2CA',
    });
    futuresArray.push({
      data: props.data.lsmgo.futures.map((e: any) => [
        new Date(e[0]).getTime(),
        e[1],
      ]),
      name: 'MGO',
      color: '#95D2CA',
    });
  }
  if (props.data.hsfo) {
    historyArray.push({
      data: props.data.hsfo.history.map((e: any) => [
        new Date(e[0]).getTime(),
        e[1],
      ]),
      name: 'HSFO',
      color: '#D46C75',
    });
    futuresArray.push({
      data: props.data.hsfo.futures.map((e: any) => [
        new Date(e[0]).getTime(),
        e[1],
      ]),
      name: 'HSFO',
      color: '#D46C75',
    });
  }
  const customOptionsHistory = {
    ...highchartsOptions,
    series: historyArray,
    title: {
      text: props.port + ' history',
      style: {
        color: '#FFF',
        font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  };

  const customOptionsFutures = {
    ...highchartsOptions,
    series: futuresArray,
    title: {
      text: props.port + ' futures',
      style: {
        color: '#FFF',
        font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  };
  return (
    <>
      <Tabs defaultActiveKey="1" className="tab-nav-link">
        <Tab eventKey="1" title="Graphs">
          {/* <Row md={2} sm={1} xs={1}>
          <Col> */}
          <HighchartsReact
            highcharts={Highcharts}
            options={customOptionsHistory}
          />
          {/* </Col>
          <Col> */}
          <HighchartsReact
            highcharts={Highcharts}
            options={customOptionsFutures}
          />
          {/* </Col>
        </Row> */}
        </Tab>
        <Tab eventKey="2" title="VLSFO futures">
          <Table
            className={'bunker-future-table'}
            striped
            bordered
            hover
            size="sm"
            responsive
          >
            <thead>
              <tr>
                <th>Month/Year</th>
                <th style={{ textAlign: 'right' }}>Price</th>
              </tr>
            </thead>
            <tbody>
              {futuresArray[0].data[1] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[1][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[1][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[2] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[2][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[2][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[3] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[3][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[3][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[4] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[4][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[4][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[5] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[5][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[5][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[6] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[6][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[6][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[7] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[7][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[7][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[8] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[8][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[8][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[9] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[9][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[9][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[10] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[10][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[10][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[11] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[11][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[11][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[12] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[12][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[12][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[13] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[13][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[13][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[14] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[14][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[14][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[15] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[15][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[15][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[16] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[16][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[16][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[17] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[17][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[17][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[18] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[18][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[18][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[19] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[19][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[19][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[20] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[20][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[20][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[21] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[21][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[21][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[22] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[22][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[22][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[23] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[23][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[23][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[24] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[24][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[24][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[25] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[25][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[25][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[26] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[26][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[26][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
              {futuresArray[0].data[27] ? (
                <tr>
                  <td>
                    {dayjs(new Date(futuresArray[0].data[27][0])).format(
                      'MMMM YYYY'
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(futuresArray[0].data[27][1])}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Tab>
        {props.data.lsmgo && (
          <Tab eventKey="3" title="MGO futures">
            <Table
              className={'bunker-future-table'}
              striped
              bordered
              hover
              size="sm"
              responsive
            >
              <thead>
                <tr>
                  <th>Month/Year</th>
                  <th style={{ textAlign: 'right' }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {futuresArray[1].data[1] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[1][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[1][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[2] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[2][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[2][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[3] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[3][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[3][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[4] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[4][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[4][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[5] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[5][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[5][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[6] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[6][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[6][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[7] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[7][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[7][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[8] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[8][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[8][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[9] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[9][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[9][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[10] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[10][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[10][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[11] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[11][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[11][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[12] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[12][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[12][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[13] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[13][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[13][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[14] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[14][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[14][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[15] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[15][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[15][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[16] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[16][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[16][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[17] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[17][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[17][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[18] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[18][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[18][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[19] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[19][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[19][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[20] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[20][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[20][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[21] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[21][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[21][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[22] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[22][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[22][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[23] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[23][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[23][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[24] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[24][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[24][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[25] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[25][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[25][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[26] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[26][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[26][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {futuresArray[1].data[27] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[1].data[27][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[1].data[27][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Tab>
        )}
        {props.data.hsfo && (
          <Tab eventKey="4" title="HSFO futures">
            <Table
              className={'bunker-future-table'}
              striped
              bordered
              hover
              size="sm"
              responsive
            >
              <thead>
                <tr>
                  <th>Month/Year</th>
                  <th style={{ textAlign: 'right' }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {futuresArray[2].data[1] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[1][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[1][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[2] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[2][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[2][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[3] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[3][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[3][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[4] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[4][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[4][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[5] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[5][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[5][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[6] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[6][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[6][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[7] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[7][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[7][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[8] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[8][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[8][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[9] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[9][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[9][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[10] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[10][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[10][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[11] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[11][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[11][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[12] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[12][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[12][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[13] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[13][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[13][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[14] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[14][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[14][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[15] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[15][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[15][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[16] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[16][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[16][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[17] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[17][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[17][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[18] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[18][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[18][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[19] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[19][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[19][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[20] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[20][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[20][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[21] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[21][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[21][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[22] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[22][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[22][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[23] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[23][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[23][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[24] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[24][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[24][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[25] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[25][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[25][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[26] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[26][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[26][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
                {futuresArray[2].data[27] ? (
                  <tr>
                    <td>
                      {dayjs(new Date(futuresArray[2].data[27][0])).format(
                        'MMMM YYYY'
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(futuresArray[2].data[27][1])}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>-</td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Tab>
        )}
      </Tabs>
    </>
  );
};
