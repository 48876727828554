import { ValuationSearchRequest as FleetSearchRequest, ValuationSearchRequestGeneralInfo as FleetSearchRequestGeneralInfo } from "../server-types";
import { GeneralForm, RangeFormState } from "../state/searchFleet";

interface FOSRange {
  from?: number | string
  to?: number | string
}


interface InputFormat  {
  general: GeneralForm 
}



export const convertToFleetSearchPayload = (input: InputFormat): FleetSearchRequest => { 
  
  const mapRange = (source: RangeFormState, target: any, key: any) => { 

    const createRange = source.from !== '' || source.to !== '';
    if (!createRange) return; // neither from or to was specified

    // Special handling: If (Built), include last year (map to db values)
    let valuesPrepared = {...source};

    if (key === 'built') {
      if (valuesPrepared.from !== '') valuesPrepared.from = `${valuesPrepared.from}-01-01`;
      if (valuesPrepared.to !== '') valuesPrepared.to = `${valuesPrepared.to}-12-31`;
    }

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== '') targetRange.from = valuesPrepared.from; 
    if (valuesPrepared.to !== '') targetRange.to = valuesPrepared.to;
    target[key] = targetRange;
  }

  const generalPayload: FleetSearchRequestGeneralInfo = {};  
  
  // --- MAP: GENERAL 
  // prepare general (str)
  if (input.general.IMO !== '') generalPayload.IMO = Number(input.general.IMO);
  if (input.general.Vessel !== '') generalPayload.Vessel = input.general.Vessel;

  // pick first (arr to str) (TODO: make these arr to arr later on)

  if (input.general.Scrubber === '1') generalPayload.Scrubber = 'True';
  if (input.general.Scrubber === '0') generalPayload.Scrubber = null;
  // if (input.general.groupOwner.length !== 0) generalPayload.groupOwner = input.general.groupOwner[0].value;

  // pick arr (arr to arr)
  if (input.general.ShipSize.length !== 0) generalPayload.ShipSize = input.general.ShipSize.map((el) => { return el.value });;  // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.ShipType.length !== 0) generalPayload.ShipType = input.general.ShipType.map((el) => { return el.value });;
  if (input.general.ShipStatus.length !== 0) generalPayload.ShipStatus = input.general.ShipStatus.map((el) => { return el.value });;
  if (input.general.CurrentStatus.length !== 0) generalPayload.CurrentStatus = input.general.CurrentStatus.map((el) => { return el.value });;
  if (input.general.Shipbuilder.length !== 0) generalPayload.Shipbuilder = input.general.Shipbuilder.map((el) => { return el.value });;
  if (input.general.IceClass.length !== 0) generalPayload.IceClass = input.general.IceClass.map((el) => { return el.value });;
  if (input.general.RegisteredOwner.length !== 0) generalPayload.RegisteredOwner = input.general.RegisteredOwner.map((el) => { return el.value });;

  // Ranges
  mapRange(input.general.DateOfBuild, generalPayload, 'DateOfBuild');
  mapRange(input.general.DeadWeight, generalPayload, 'DeadWeight');
  mapRange(input.general.GasCapacity, generalPayload, 'GasCapacity');
  mapRange(input.general.DeathDate, generalPayload, 'DeathDate');

  
  return {
    general: generalPayload,
  };
}


// client?: string[]
// effectiveDate?: FOSRange
// imo?: number
// vesselName?: string
// groupOwner?: string[]
// issuedTo?: string[]
// subtype?: string[]
// shipyard?: string[]
// built?: FOSRange
// DWT?: FOSRange
// BHP?: FOSRange
// dive?: string[]