import { useEffect, useState } from 'react';

export function useSetNameAndEmail(segmentStateValue: string) {
  const [senderEmail, setSenderEmail] = useState('');
  const [senderName, setSenderName] = useState('');

  useEffect(() => {
    const setSenderNameAndEmail = () => {
      const segment = segmentStateValue;
      if (segment === 'drybulk') {
        setSenderEmail('fearnbulk@fearnleys.com');
        setSenderName('Fearnbulk');
      } else if (segment === 'tankers') {
        setSenderEmail('fearntank@fearnleys.com');
        setSenderName('Fearntank');
      } else if (segment === 'lpg') {
        setSenderEmail('fearngas@fearnleys.com');
        setSenderName('Fearngas');
      } else if (segment === 'lng') {
        setSenderEmail('lngchartering@fearnleys.com');
        setSenderName('LNG Chartering');
      }
    };
    setSenderNameAndEmail();
  }, [segmentStateValue]);

  return { senderEmail, senderName };
}
