import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { SegmentMatch } from '../state/segment';
import { FFAFetch } from './FFAFetch';

export interface FFAResponse {
  segment: string;
  ffa: {
    route: string;
    unit: string;
    data: {
      date: Date;
      period: string;
      tsid: number;
      fwd_curve_name: string;
      value: number;
      change: number;
    }[];
  }[];
}

function FFAtableDryCargo() {
  return (
    <div>
      <Tabs className="custom-tabs" defaultActiveKey="capesize">
        <Tab eventKey="capesize" title="Capesize">
          <FFAFetch segment={'Capesize'} unit={'$/t'} />
        </Tab>
        <Tab eventKey="panamax" title="Panamax">
          <FFAFetch segment={'Panamax'} unit={'$/t'} />
        </Tab>
        <Tab eventKey="supramax" title="Supramax and Handysize">
          <FFAFetch segment={'Supramax'} unit={'$/t'} />
        </Tab>
      </Tabs>
    </div>
  );
}

function FFATableTankers() {
  return (
    <div>
      <Tabs className="custom-tabs" defaultActiveKey="vlcc">
        <Tab eventKey="vlcc" title="VLCC">
          <FFAFetch segment={'VLCC'} unit={'WS'} />
        </Tab>
        <Tab eventKey="suezmax" title="Suezmax">
          <FFAFetch segment={'Suezmax'} unit={'WS'} />
        </Tab>
        <Tab eventKey="aframax" title="Aframax">
          <FFAFetch segment={'Aframax'} unit={'WS'} />
        </Tab>
      </Tabs>
    </div>
  );
}

function FFATableLPG() {
  return (
    <div>
      <FFAFetch segment={'LPG'} unit={'$'} />
    </div>
  );
}

function FFATableLNG() {
  return (
    <div>
      <FFAFetch segment={'LNG'} unit={'$'} />
    </div>
  );
}

export function FFATables({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  const [pageComponent, setPageComponent] = useState<any>();

  useEffect(() => {
    if ((segmentStateValue as string) === SegmentMatch.DryCargo) {
      setPageComponent(<FFAtableDryCargo />);
    }
    if (segmentStateValue === SegmentMatch.Tankers) {
      setPageComponent(<FFATableTankers />);
    }
    if (segmentStateValue === SegmentMatch.LPG) {
      setPageComponent(<FFATableLPG />);
    }
    if (segmentStateValue === SegmentMatch.LNG) {
      setPageComponent(<FFATableLNG />);
    }
  }, [segmentStateValue]);

  return <div className="middle-stuff pt-2">{pageComponent}</div>;
}
