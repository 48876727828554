const API_PATH_LOCAL = 'http://localhost:8080/api';

const API_PATH_DEV_PUBLIC =
  'https://dfearnportal-backend.azurewebsites.net/api';
const API_PATH_PUBLIC = 'https://api.fearnportal.com/api';

export const getAPIPath = () => {
  return process.env.NODE_ENV === 'development'
    ? API_PATH_LOCAL
    : API_PATH_PUBLIC;
};
