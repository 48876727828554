import { getAPIPath } from '../getAPIPath';

export async function fetchWithSession(url: string): Promise<any> {
  return await fetch(getAPIPath() + url, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
}
