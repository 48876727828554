export enum Segment {
  DryCargo = 'Dry Bulk',
  Tankers = 'Tankers',
  LPG = 'LPG',
  LNG = 'LNG',
}

export enum SegmentMatch {
  DryCargo = 'drybulk',
  Tankers = 'tankers',
  LPG = 'lpg',
  LNG = 'lng',
}

export function getTitleForSegment(segment: string): string {
  if (segment === SegmentMatch.DryCargo) {
    return Segment.DryCargo;
  } else if (segment === SegmentMatch.Tankers) {
    return Segment.Tankers;
  } else if (segment === SegmentMatch.LPG) {
    return Segment.LPG;
  } else if (segment === SegmentMatch.LNG) {
    return Segment.LNG;
  } else {
    return 'not found';
  }
}
