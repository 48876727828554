import { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faChevronRight,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';
import { getAPIPath } from '../getAPIPath';
import { FuelModal } from './FuelAssets/FuelModal';
import { OffcanvasHeader } from 'react-bootstrap';
import { OffcanvasTitle } from 'react-bootstrap';
import { FuelChart } from './FuelAssets/FuelChart';
import { fetchWithSession } from './fetchWithSession';

const renderRouteTrend = (routeTrend: any) => {
  if (routeTrend === 0) {
    return (
      <p className="card-text rates-sub-header">
        <NumberFormat
          value={routeTrend}
          displayType={'text'}
          prefix={'$'}
          decimalScale={0}
          className="font-weight-bold"
        />
        <FontAwesomeIcon icon={faChevronRight} />
      </p>
    );
  } else if (routeTrend > 0) {
    return (
      <p className="card-text rate-up">
        <NumberFormat
          value={routeTrend}
          displayType={'text'}
          prefix={'$'}
          decimalScale={0}
          className="font-weight-bold"
        />
        <FontAwesomeIcon icon={faChevronUp} />
      </p>
    );
  } else {
    return (
      <p className="card-text rate-down">
        <NumberFormat
          value={routeTrend}
          displayType={'text'}
          prefix={'$'}
          decimalScale={0}
          className="font-weight-bold"
        />
        <FontAwesomeIcon icon={faChevronDown} />
      </p>
    );
  }
};

function FuelCard(props: any) {
  const { port, model, onClick } = props;
  return (
    <div>
      {model && (
        <div
          className="card shadow-lg navbar-gradient"
          onClick={onClick}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <div className="card-body">
            <h6 style={{ fontFamily: 'Segoe UI' }} className="card-title">
              {port}
            </h6>
            <div className="d-flex justify-content-between align-items-center">
              <h6
                style={{ fontFamily: 'Segoe UI' }}
                className="card-title price"
              >
                <NumberFormat
                  value={model.vlsfo.val}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />
              </h6>
              <p style={{ fontFamily: 'Segoe UI' }} className="card-title">
                VLSFO
              </p>
              {renderRouteTrend(model.vlsfo.routeTrend)}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6
                style={{ fontFamily: 'Segoe UI' }}
                className="card-title price"
              >
                {model.lsmgo ? (
                  <NumberFormat
                    value={model.lsmgo.val}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                ) : (
                  <span>&nbsp;</span>
                )}
              </h6>
              {model.lsmgo && (
                <>
                  <p style={{ fontFamily: 'Segoe UI' }} className="card-title">
                    MGO
                  </p>

                  {renderRouteTrend(model.lsmgo.routeTrend)}
                </>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6
                style={{ fontFamily: 'Segoe UI' }}
                className="card-title price"
              >
                {model.hsfo ? (
                  <NumberFormat
                    value={model.hsfo.val}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                ) : (
                  <span>&nbsp;</span>
                )}
              </h6>
              {model.hsfo && (
                <>
                  <p style={{ fontFamily: 'Segoe UI' }} className="card-title">
                    HSFO
                  </p>

                  {renderRouteTrend(model.hsfo.routeTrend)}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

async function getFuelData() {
  const response = await fetchWithSession(`/bunker-prices`);
  return await response.json();
}

export function FuelPrices() {
  const emptyProduct = {
    val: 0,
    routeTrend: 0,
    futures: [],
    history: [],
  };
  const emptyFuelState = {
    vlsfo: { ...emptyProduct },
    lsmgo: { ...emptyProduct },
    hsfo: { ...emptyProduct },
  };
  const [fuel, setFuel] = useState({
    durban: { ...emptyFuelState },
    fujairah: { ...emptyFuelState },
    gibraltar: { ...emptyFuelState },
    houston: { ...emptyFuelState },
    neworleans: { ...emptyFuelState },
    qingdao: { ...emptyFuelState },
    rotterdam: { ...emptyFuelState },
    santos: { ...emptyFuelState },
    singapore: { ...emptyFuelState },
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [port, setPort] = useState('');
  const [data, setData] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (x: string, y: any) => {
    setShow(true);
    setPort(x);
    setData(y);
  };
  useEffect(() => {
    getFuelData()
      .then((fuel) => {
        setFuel(fuel.response);
        setDataLoaded(true);
      })
      .catch((error) => console.warn('error', error));
  }, []);
  return (
    <Container>
      {/* <Button variant="primary" onClick={handleOffShow}>
        Launch
      </Button>

          <Offcanvas show={showOff} onHide={handleOffClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Offcanvas</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              Some text as placeholder. In real life you can have the elements you
              have chosen. Like, text, images, lists, etc.
            </Offcanvas.Body>
          </Offcanvas> */}
      <p className="text-grey">
        <FontAwesomeIcon
          icon={faCalendarAlt}
          className="fuel-header-calendar-icon"
        />
        Click rate to view history + futures
      </p>
      <p className="rates-sub-sub-header">
        <b>All prices are based on swap prices.</b>
      </p>
      {!dataLoaded && (
        <>
          <Spinner animation="border" />
        </>
      )}
      {dataLoaded && (
        <>
          <Row>
            <Col>
              <Row>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Singapore', fuel.singapore)}
                    port="Singapore"
                    model={fuel.singapore}
                  ></FuelCard>
                </Col>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Rotterdam', fuel.rotterdam)}
                    port="Rotterdam"
                    model={fuel.rotterdam}
                  ></FuelCard>
                </Col>
              </Row>
              <Row>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('New Orleans', fuel.neworleans)}
                    port="New Orleans"
                    model={fuel.neworleans}
                  ></FuelCard>
                </Col>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Santos', fuel.santos)}
                    port="Santos"
                    model={fuel.santos}
                  ></FuelCard>
                </Col>
              </Row>
              <Row>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Gibraltar', fuel.gibraltar)}
                    port="Gibraltar"
                    model={fuel.gibraltar}
                  ></FuelCard>
                </Col>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Durban', fuel.durban)}
                    port="Durban"
                    model={fuel.durban}
                  ></FuelCard>
                </Col>
              </Row>
              <Row>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Qingdao', fuel.qingdao)}
                    port="Qingdao"
                    model={fuel.qingdao}
                  ></FuelCard>
                </Col>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Fujairah', fuel.fujairah)}
                    port="Fujairah"
                    model={fuel.fujairah}
                  ></FuelCard>
                </Col>
              </Row>
              <Row>
                <Col className="rates">
                  <FuelCard
                    onClick={() => handleShow('Houston', fuel.houston)}
                    port="Houston"
                    model={fuel.houston}
                  ></FuelCard>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <FuelChart data={fuel} />
            </Col>
          </Row>
        </>
      )}
      <Offcanvas
        style={{ backgroundColor: 'whitesmoke', width: '600px' }}
        show={show}
        onHide={handleClose}
        centered
        scroll
      >
        <OffcanvasHeader closeButton style={{ filter: 'invert(0)' }}>
          <OffcanvasTitle>{port}</OffcanvasTitle>
        </OffcanvasHeader>
        <div style={{ overflow: 'auto' }}>
          <Container>
            <FuelModal port={port} data={data} />
          </Container>
        </div>
        {/* <div>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </div> */}
      </Offcanvas>
    </Container>
  );
}
