import Highcharts from 'highcharts';
import { Tab, Tabs } from 'react-bootstrap';
import { SegmentMatch } from '../state/segment';
import { GetRateData } from './GetRateData';
import { CapeTables, HandysizeTables, PmxTables, SupraTables } from './RateTables/DryBulkTables';
import { LNGTables } from './RateTables/LNGTables';
import { LPGTables } from './RateTables/LPGTables';
import { AfraTables, SuezTables, VLCCTables } from './RateTables/TankTables';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export interface Metric {
  date: Date;
  val: number;
}

export interface MetricResponse {
  header: string;
  data: {
    name: string;
    yAxis?: number;
    timeSeries: any[];
  }[];
}

export interface Props {
  segment: string;
}

function LNGRateData() {
  return (
    <>
      <button
        disabled
        className="nav-link active"
        style={{ border: 0, color: 'black', fontWeight: 500 }}
      >
        LNG
      </button>
      <LNGTables />
      <GetRateData segment="LNGC" />
    </>
  );
}

export function RateData({
  segmentStateValue,
}: {
  segmentStateValue: string;
}): JSX.Element {
  return (
    <div className="middle-stuff">
      {(segmentStateValue as string) === SegmentMatch.DryCargo && (
        <DryCargoRateData />
      )}
      {segmentStateValue === SegmentMatch.Tankers && <TankersRateData />}
      {segmentStateValue === SegmentMatch.LPG && <LPGRateData />}
      {segmentStateValue === SegmentMatch.LNG && <LNGRateData />}
    </div>
  );
}

function DryCargoRateData() {
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      transition={false}
      id="dry-tabs"
      className="custom-tabs"
      defaultActiveKey="capesize"
    >
      <Tab eventKey="capesize" title="Capesize/Newcastlemax">
        <CapeTables />
        <GetRateData segment="Capesize" />
      </Tab>
      <Tab eventKey="panamax" title="Panamax/Kamsarmax">
        <PmxTables />
        <GetRateData segment="Panamax" />
      </Tab>
      <Tab eventKey="supramax" title="Supramax/Ultramax">
        <SupraTables />
        <GetRateData segment="Supra/Ultramax" />
      </Tab>
      <Tab eventKey="handysize" title="Handysize">
        <HandysizeTables />
        <GetRateData segment="Handysize" />
      </Tab>
    </Tabs>
  );
}

function TankersRateData() {
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      transition={false}
      id="tank-tabs"
      className="custom-tabs"
      defaultActiveKey="vlcc"
    >
      <Tab eventKey="vlcc" title="VLCC">
        <VLCCTables />
        <GetRateData segment="VLCC" />
      </Tab>
      <Tab eventKey="suezmax" title="Suezmax">
        <SuezTables />
        <GetRateData segment="Suezmax" />
      </Tab>
      <Tab eventKey="aframax" title="Aframax">
        <AfraTables />
        <GetRateData segment="Aframax" />
      </Tab>
    </Tabs>
  );
}

function LPGRateData() {
  return (
    <>
      <button
        disabled
        className="nav-link active"
        style={{ border: 0, color: 'black', fontWeight: 500 }}
      >
        LPG
      </button>
      <LPGTables />
      <GetRateData segment="VLGC" />
    </>
  );
}
