export function makeScriptTag_LPG() {
  const scriptTag = document.createElement('script');
  scriptTag.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag.async = true;
  scriptTag.innerHTML = `
        {
          "symbol": "TVC:UKOIL",
      "width": "100%",
      "height": "220",
      "locale": "en",
      "dateRange": "12M",
      "colorTheme": "dark",
      "trendLineColor": "#37a6ef",
      "underLineColor": "rgba(55, 166, 239, 0.15)",
      "isTransparent": false,
      "autosize": false,
      "largeChartUrl": ""
        }
        `;
  return scriptTag;
}
