export function runScriptTagTicker2_LPG() {
  const scriptTagTicker2 = document.createElement('script');
  scriptTagTicker2.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
  scriptTagTicker2.async = true;
  scriptTagTicker2.innerHTML = `
            {
      "colorTheme": "dark",
      "dateRange": "12M",
      "showChart": false,
      "locale": "en",
      "width": "100%",
      "height": "450",
      "largeChartUrl": "",
      "isTransparent": false,
      "showSymbolLogo": true,
      "tabs": [
      {
       "title": "Commodities",
       "symbols": [
         {
           "s": "NYMEX:A7Q1!",
           "d": "Mt. Belvieu Natural Gasoline futures"
         },
         {
          "s": "NYMEX:A7Q2!",
          "d": "Mt. Belvieu Natural Gasoline futures next"
         },
         {
          "s": "NYMEX:AC01!",
          "d": "Mt. Belvieu Ethane futures"
        },
        {
         "s": "NYMEX:AC02!",
         "d": "Mt. Belvieu Ethane futures next"
        }
       ],
       "originalTitle": "Commodities"
      },
      {
       "title": "Indices",
       "symbols": [
        {
          "s": "INDEX:OBX",
          "d": "Oslo Børs"
        },
        {
          "s": "CURRENCYCOM:US500",
          "d": "S&P 500"
        },
        {
          "s": "CURRENCYCOM:US100",
          "d": "Nasdaq"
        },
        {
          "s": "XETR:DAX",
          "d": "DAX"
        },
        {
          "s": "CAPITALCOM:UK100",
          "d": "FTSE 100"
        },
        {
          "s": "OTC:ISMUF",
          "d": "MSCI China"
        },
        {
          "s": "HSI:HSI",
          "d": "Hang Seng"
        },
        {
          "s": "index:sti",
          "d": "Straits Times Index"
        },
        {
          "s": "CAPITALCOM:J225",
          "d": "Nikkei 225"
        }
      ],
      "originalTitle": "Bonds"
      },
      {
       "title": "Stocks",
       "symbols": [
         {
           "s": "OTC:AVACF",
           "d": "Avance Gas"
         },
         {
          "s": "CAPITALCOM:BWLPG",
          "d": "BW LPG"
         },
         {
           "s": "NYSE:LPG",
           "d": "Dorian LPG"
         },
         {
          "s": "NYSE:NVGS",
          "d": "Navigator Holdings"
         },
         {
           "s": "FWB:S6W",
           "d": "StealthGas Inc"
         }
       ],
       "originalTitle": "Forex"
      }
      ]
      }
            `;
  return scriptTagTicker2;
}
