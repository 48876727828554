import clsx from 'clsx';
import React from 'react';
import { Toaster } from 'react-hot-toast';
// import ReactQuill from 'react-quill';

export function MailUI({
  isSentEmail,
  senderEmail,
  subject,
  handleSubjectEntry,
  handleSelectDropdownItem,
  options,
  handleFileUpload,
  handleSubmit,
  setText,
  setSubject,
  setIsSentEmail,
}: {
  isSentEmail: boolean;
  senderEmail: string;
  subject: string;
  handleSubjectEntry: (e: any) => void;
  handleSelectDropdownItem: (e: any) => void;
  options: JSX.Element[] | undefined;
  handleFileUpload: (e: any) => void;
  handleSubmit: () => Promise<boolean>;
  setText: React.Dispatch<React.SetStateAction<any>>;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  setIsSentEmail: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const quillRef = React.useRef<any>(null);

  return (
    <div className={'tw-px-4 tw-my-32 tw-max-w-3xl tw-mx-auto tw-space-y-6'}>
      <div className={clsx('', isSentEmail ? 'tw-hidden' : '')}>
        <p
          className={
            'tw-inline tw-text-fearnleyblue px-1 tw-font-sans tw-bg-[#cadfed] tw-rounded'
          }
        >
          From
        </p>
        <p
          className={' tw-font-sans tw-text-xs tw-inline px-1 tw-text-gray-500'}
        >
          {senderEmail}
        </p>

        {/* <label
              htmlFor="subject"
              className={'tw-text-fearnleyblue tw-font-medium'}
            >
              Subject
            </label> */}
        <input
          value={subject}
          placeholder={'Add a subject'}
          className={
            'tw-border tw-border-gray-400 tw-rounded tw-block tw-py-1 tw-px-2 tw-w-full tw-outline-none'
          }
          type="text"
          onChange={(e) => handleSubjectEntry(e)}
          disabled={isSentEmail}
        />

        <div
          className={process.env.NODE_ENV === 'development' ? 'hemmelig' : ''}
        >
          {/* <ReactQuill
            ref={quillRef}
            theme="snow"
            onChange={(e) => setText(e)}
            className={
              'tw-border tw-border-gray-400 tw-rounded ignore-css tw-bg-white'
            }

            // value={text}
          /> */}
          <input
            type="text"
            onChange={(e) => setText(e.target.value)}
            className={
              'tw-border tw-border-gray-400 tw-rounded tw-block tw-py-1 tw-px-2 tw-w-full tw-outline-none'
            }
            disabled={isSentEmail}
          />
        </div>

        <label
          htmlFor="recipient"
          className={'tw-text-fearnleyblue tw-font-medium'}
        >
          Recipient
        </label>
        <select
          className={
            'tw-border tw-border-gray-400 tw-rounded tw-block tw-py-1 tw-px-2 tw-w-1/4 tw-outline-none'
          }
          name="recipient[]"
          disabled={isSentEmail}
          onChange={handleSelectDropdownItem}
        >
          {options}
        </select>

        <label htmlFor="file" className={'tw-text-fearnleyblue tw-font-medium'}>
          Attach file
        </label>
        <input
          className={'tw-text-fearnleyblue tw-hover:bg-sky-700 tw-block'}
          type="file"
          onChange={(e) => handleFileUpload(e)}
          disabled={isSentEmail}
        />
      </div>

      <div>
        <button
          className={clsx(
            'tw-border tw-border-gray-400 tw-rounded tw-p-0.5  tw-font-medium tw-bg-fearnleyblue tw-text-white tw-w-1/6 tw-mr-2',
            isSentEmail ? 'tw-hidden' : 'hover:tw-bg-blue-600'
          )}
          type="submit"
          onClick={() => {
            handleSubmit();
            setText('');
            setSubject('');

            quillRef?.current?.getEditor().setText('');
          }}
          disabled={isSentEmail}
        >
          Send
        </button>
      </div>

      <div>
        <Toaster />
      </div>
      <>
        <div className={'tw-w-full tw-flex tw-items-center tw-justify-center'}>
          <button
            className={clsx(
              'tw-border tw-border-gray-400 tw-rounded tw-py-2 tw-px-4  tw-font-medium tw-bg-[#36cf8c] tw-text-white tw-w-1/8 ',
              isSentEmail ? ' hover:tw-text-fearnleyblue' : 'tw-hidden'
            )}
            onClick={() => setIsSentEmail(false)}
          >
            New mail
          </button>
        </div>
      </>
    </div>
  );
}
