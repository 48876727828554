import { useEffect, useState } from 'react';
import { Button, Stack, Tab, Tabs } from 'react-bootstrap';
import { SegmentMatch } from '../state/segment';
import { GetCargoCharts } from './GetCargoCharts';

export interface OverviewProps {
  segment: string;
  timeUnit: 'MONTH' | 'QUARTER' | 'YEAR';
  tradeType: 'export' | 'import';
}
interface CargoFlow {
  date: Date;
  cargoes: number;
  dwtmiles: number;
}
interface TopPorts {
  loadPort?: string;
  flagUrl?: string;
  loadcountry?: string;
  loadregion?: string;
  dischargePort?: string;
  dischargecountry?: string;
  dischargeregion?: string;
  cargoes?: number;
  discharges?: number;
  dwtmiles: number;
}
interface TopDischCountry {
  dischargecountry?: string;
  dischargeregion?: string;
  flagUrl: string;
  discharges: number;
  dwtmiles: number;
}
interface TopLoadCountry {
  loadcountry: string;
  flagUrl: string;
  cargoes: number;
  dwtmiles: number;
}
export interface CargoFlowResponse {
  name: string;
  timeSeries: CargoFlow[];
  flagUrl: string;
  prevYearTopPorts: TopPorts[];
  prevYearTopDischCountries?: TopDischCountry[];
  prevYearTopLoadCountries?: TopLoadCountry[];
}

export function numFormatter(num: number) {
  if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  }
  return num.toLocaleString();
}

export function CargoData({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  const [timeUnit, setTimeUnit] = useState<'MONTH' | 'QUARTER' | 'YEAR'>(
    'QUARTER'
  );
  const [tradeType, settradeType] = useState<'export' | 'import'>('export');
  const [timeParam, setTimeParam] = useState<'MONTH' | 'QUARTER' | 'YEAR'>(
    'QUARTER'
  );
  const [tradeTypeParam, setTradeTypeParam] = useState<'export' | 'import'>(
    'export'
  );

  useEffect(() => {
    setTimeParam(timeUnit);
  }, [timeUnit]);

  useEffect(() => {
    setTradeTypeParam(tradeType);
  }, [tradeType]);

  function ButtonRow(): JSX.Element {
    return (
      <Stack direction="horizontal">
        <div
          style={{
            marginLeft: '12px',
            marginBottom: '8px',
            marginTop: '8px',
          }}
        >
          <Button
            active={tradeType === 'export' && true}
            size="sm"
            variant="outline-secondary"
            onClick={() => settradeType('export')}
          >
            Export
          </Button>
          <Button
            active={tradeType === 'import' && true}
            size="sm"
            variant="outline-secondary"
            onClick={() => settradeType('import')}
          >
            Import
          </Button>
        </div>
        <div className="ms-auto" style={{ marginRight: '12px' }}>
          <Button
            active={timeUnit === 'MONTH' && true}
            size="sm"
            variant="outline-secondary"
            onClick={() => setTimeUnit('MONTH')}
          >
            M
          </Button>
          <Button
            active={timeUnit === 'QUARTER' && true}
            size="sm"
            variant="outline-secondary"
            onClick={() => setTimeUnit('QUARTER')}
          >
            Q
          </Button>
          <Button
            active={timeUnit === 'YEAR' && true}
            size="sm"
            variant="outline-secondary"
            onClick={() => setTimeUnit('YEAR')}
          >
            Y
          </Button>
        </div>
      </Stack>
    );
  }

  return (
    <div className="middle-stuff">
      {segmentStateValue === SegmentMatch.DryCargo && (
        <>
          <Tabs
            mountOnEnter
            unmountOnExit
            transition={false}
            id="dry-tabs"
            className="custom-tabs"
            defaultActiveKey="capesize"
          >
            <Tab eventKey="vloc" title="VLOC">
              <ButtonRow />
              <GetCargoCharts
                timeUnit={timeParam}
                segment="vloc"
                tradeType={tradeTypeParam}
              />
            </Tab>
            <Tab eventKey="capesize" title="Capesize/Newcastlemax">
              <ButtonRow />
              <GetCargoCharts
                timeUnit={timeParam}
                segment="capesize"
                tradeType={tradeTypeParam}
              />
            </Tab>
            <Tab eventKey="panamax" title="Panamax/Kamsarmax">
              <ButtonRow />
              <GetCargoCharts
                timeUnit={timeParam}
                segment="panamax_bulk"
                tradeType={tradeTypeParam}
              />
            </Tab>
            <Tab eventKey="supramax" title="Supramax/Ultramax">
              <ButtonRow />
              <GetCargoCharts
                timeUnit={timeParam}
                segment="supramax"
                tradeType={tradeTypeParam}
              />
            </Tab>
          </Tabs>
        </>
      )}
      {segmentStateValue === SegmentMatch.Tankers && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          id="tank-tabs"
          className="custom-tabs"
          defaultActiveKey="vlcc"
        >
          <Tab eventKey="vlcc" title="VLCC">
            <ButtonRow />
            <GetCargoCharts
              timeUnit={timeParam}
              segment="vlcc"
              tradeType={tradeTypeParam}
            />
          </Tab>
          <Tab eventKey="suezmax" title="Suezmax">
            <ButtonRow />
            <GetCargoCharts
              timeUnit={timeParam}
              segment="suezmax"
              tradeType={tradeTypeParam}
            />
          </Tab>
          <Tab eventKey="aframax" title="Aframax">
            <ButtonRow />
            <GetCargoCharts
              timeUnit={timeParam}
              segment="aframax"
              tradeType={tradeTypeParam}
            />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.LPG && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          id="gas-tabs"
          className="custom-tabs"
          defaultActiveKey="vlgc"
        >
          <Tab eventKey="vlgc" title="VLGC">
            <ButtonRow />
            <GetCargoCharts
              timeUnit={timeParam}
              segment="vlgc"
              tradeType={tradeTypeParam}
            />
          </Tab>
          <Tab eventKey="lgc" title="LGC">
            <ButtonRow />
            <GetCargoCharts
              timeUnit={timeParam}
              segment="lgc"
              tradeType={tradeTypeParam}
            />
          </Tab>
          <Tab eventKey="mgc" title="MGC">
            <ButtonRow />
            <GetCargoCharts
              timeUnit={timeParam}
              segment="mgc"
              tradeType={tradeTypeParam}
            />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.LNG && (
        <>
          <button
            disabled
            className="nav-link active"
            style={{ border: 0, color: 'black', fontWeight: 500 }}
          >
            LNGC
          </button>
          <ButtonRow />
          <GetCargoCharts
            timeUnit={timeParam}
            segment="lng"
            tradeType={tradeTypeParam}
          />
        </>
      )}
    </div>
  );
}
