import { Tab, Tabs } from 'react-bootstrap';
import { SegmentMatch } from '../state/segment';
import { GetMarketData } from './GetMarketData';

export interface Metric {
  date: Date;
  val: number;
}

export interface MetricResponse {
  segment: string;
  subsegment: string;
  metric: {
    chartType: string;
    heading: string;
    data: {
      yAxis: number;
      legend: string;
      timeSeries: Metric[];
    }[];
  }[];
}

export interface Props {
  segment: string;
  subsegment: string;
}

export function MarketData({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  return (
    <div className="middle-stuff">
      {segmentStateValue === SegmentMatch.DryCargo && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          id="dry-tabs"
          className="custom-tabs"
          defaultActiveKey="capesize"
        >
          <Tab eventKey="capesize" title="Capesize/Newcastlemax">
            <GetMarketData segment={'dry_bulk'} subsegment={'capesize'} />
          </Tab>
          <Tab eventKey="panamax" title="Panamax/Kamsarmax">
            <GetMarketData segment={'dry_bulk'} subsegment={'panamax'} />
          </Tab>
          <Tab eventKey="supramax" title="Supramax/Ultramax">
            <GetMarketData segment={'dry_bulk'} subsegment={'supramax'} />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.Tankers && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          id="tank-tabs"
          className="custom-tabs"
          defaultActiveKey="vlcc"
        >
          <Tab eventKey="vlcc" title="VLCC">
            <GetMarketData segment={'tanker'} subsegment="vlcc" />
          </Tab>
          <Tab eventKey="suezmax" title="Suezmax">
            <GetMarketData segment={'tanker'} subsegment="suezmax" />
          </Tab>
          <Tab eventKey="aframax" title="Aframax">
            <GetMarketData segment={'tanker'} subsegment="aframax" />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.LPG && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          id="gas-tabs"
          className="custom-tabs"
          defaultActiveKey="vlgc"
        >
          <Tab eventKey="vlgc" title="VLGC">
            <GetMarketData segment={'lpg'} subsegment="vlgc" />
          </Tab>
          <Tab eventKey="lgc" title="LGC">
            <GetMarketData segment={'lpg'} subsegment="lgc" />
          </Tab>
          <Tab eventKey="mgc" title="MGC">
            <GetMarketData segment={'lpg'} subsegment="mgc" />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.LNG && (
        <>
          <button
            disabled
            className="nav-link active"
            style={{ border: 0, color: 'black', fontWeight: 500 }}
          >
            LNGC
          </button>
          <GetMarketData segment={'lng'} subsegment="lng" />
        </>
      )}
    </div>
  );
}
