import { fetchWithSession } from 'components/fetchWithSession';
import { useEffect, useState } from 'react';
import { cleanupIndexJScripts } from '../cleanupIndexJScripts';
import { SharePointFolder } from '../Index';
import { loadIndexJSScripts } from '../ThirdParty/loadIndexJSScripts';

export function useGetSharePointData(segmentStateValue: string) {
  const [error, setError] = useState(false);

  const [apiFolder, setApiFolders] = useState<undefined | SharePointFolder[]>(
    undefined
  );
  const [sharePointLoaded, setSharePointLoaded] = useState(false);
  const [bunkerPricesLoaded, setBunkerPricesLoaded] = useState(false);
  const [, setBunkerPrices] = useState<any>(undefined);

  useEffect(() => {
    const loadSharePointDataFromAPI = async () => {
      try {
        // Fetch sharepoint folders
        const sharepointFoldersRes = await fetchWithSession(`/latest-research`);
        // check the sharepoint folders response
        if (sharepointFoldersRes.status !== 200) {
          setSharePointLoaded(true);
          setError(true);
          return;
        }

        const sharepointFolders = await sharepointFoldersRes.json();

        // all good, files  returned
        setApiFolders(sharepointFolders.response);

        setError(false);
        setSharePointLoaded(true);
      } catch (err) {
        setError(true);
        setSharePointLoaded(true);
      }
    };

    const loadBunkerPricesFromAPI = async () => {
      try {
        // Fetch rates
        const bunkerPricesRes = await fetchWithSession(`/bunker-prices`);
        if (bunkerPricesRes.status !== 200) {
          setBunkerPricesLoaded(true);
          setError(true);
          return;
        }

        const bunkerPrices = await bunkerPricesRes.json();
        setBunkerPrices(bunkerPrices.response);
        // all good, files  returned
        // setApiFolders(sharepointFolders.response);
        setError(false);
        setBunkerPricesLoaded(true);
      } catch (err) {
        setError(true);
        setBunkerPricesLoaded(true);
      }
    };

    const loadAsyncResources = async () => {
      // Call both async funcs
      await Promise.all([
        loadSharePointDataFromAPI(),
        loadBunkerPricesFromAPI(),
      ]);
      // Done!
      loadIndexJSScripts(segmentStateValue);
    };

    loadAsyncResources();

    // Return cleanup function
    return () => {
      // Code here is only run when component is about to unload
      cleanupIndexJScripts();
    };
  }, [segmentStateValue]);

  return { sharePointLoaded, bunkerPricesLoaded, error, apiFolder };
}
