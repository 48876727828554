import { fetchWithSession } from 'components/fetchWithSession';
import { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';

function colorCell(content: number, decimals: number = 0): JSX.Element {
  return content > 0 ? (
    <td style={{ color: 'green' }}>
      {'+' +
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(content)}
    </td>
  ) : content < 0 ? (
    <td style={{ color: 'red' }}>
      {new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(content)}
    </td>
  ) : (
    <td>-</td>
  );
}

export const LPGTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(`/rate-tables?segment=VLGC`);
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row>
        {reportData && (
          <>
            <Col>
              <div style={{ height: '250px', overflow: 'auto' }}>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.ratesSpot.map(
                      (
                        rate: {
                          route: string;
                          dollar_rate: number;
                          date: string;
                        },
                        index: number
                      ) => {
                        return (
                          <tr key={rate.route}>
                            <td>{rate.route.toString()}</td>
                            <td>
                              {new Intl.DateTimeFormat('en-US', {
                                year: '2-digit',
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(rate.date))}
                            </td>
                            <td>
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(rate.dollar_rate)}
                            </td>
                            {colorCell(
                              rate.dollar_rate -
                                reportData.prevRatesLPGSpot[index].dollar_rate,
                              0
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>LPG/FOB - Propane</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.ratesLpgfobPropane.map(
                      (
                        rate: {
                          route: string;
                          dollar_rate: number;
                          date: string;
                        },
                        index: number
                      ) => {
                        return (
                          <tr key={rate.route}>
                            <td>{rate.route.toString()}</td>
                            <td>
                              {new Intl.DateTimeFormat('en-US', {
                                year: '2-digit',
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(rate.date))}
                            </td>
                            <td>
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(rate.dollar_rate)}
                            </td>
                            {colorCell(
                              rate.dollar_rate -
                                reportData.prevRatesLPGFobPropane[index]
                                  .dollar_rate,
                              1
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>LPG/FOB - Butane</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.ratesLpgfobButane.map(
                      (
                        rate: {
                          route: string;
                          dollar_rate: number;
                          date: string;
                        },
                        index: number
                      ) => {
                        return (
                          <tr key={rate.route}>
                            <td>{rate.route.toString()}</td>
                            <td>
                              {new Intl.DateTimeFormat('en-US', {
                                year: '2-digit',
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(rate.date))}
                            </td>
                            <td>
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(rate.dollar_rate)}
                            </td>
                            {colorCell(
                              rate.dollar_rate -
                                reportData.prevRatesLPGFobButane[index]
                                  .dollar_rate,
                              1
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
