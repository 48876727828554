import { fetchWithSession } from 'components/fetchWithSession';
import { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';

function colorCell(content: number, decimals: number = 1): JSX.Element {
  return content > 0 ? (
    <td style={{ color: 'green' }}>
      {'+' +
        new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(content)}
    </td>
  ) : content < 0 ? (
    <td style={{ color: 'red' }}>
      {new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(content)}
    </td>
  ) : (
    <td>-</td>
  );
}

export const VLCCTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(`/rate-tables?segment=VLCC`);
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <Row>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 Year T/C</td>
                      <td>
                        {reportData.rates1yeartc[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.rates1yeartc[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartc[0]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartc[0]?.dollar_rate -
                          reportData.prevRates1yearTc[0]?.dollar_rate,
                        0
                      )}
                    </tr>
                    {/* <tr>
                  <td>
                    {reportData.ratesVlcc[0]?.route.toString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}}>
                    {reportData.ratesVlcc[0] && new Intl.DateTimeFormat('en-US', { year: "2-digit", month: "short",day: "numeric"}).format(new Date(reportData.ratesVlcc[0]?.date))}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-US', { style: 'decimal'}).format(reportData.ratesVlcc[0]?.dollar_rate)}
                  </td>
                    {colorCell(reportData.ratesVlcc[0]?.dollar_rate - reportData.prevRatesVlcc[0]?.dollar_rate,0)}
                </tr> 
                <tr>
                  <td>
                    {reportData.ratesVlcc[1]?.route.toString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}}>
                    {reportData.ratesVlcc[1] && new Intl.DateTimeFormat('en-US', { year: "2-digit", month: "short",day: "numeric"}).format(new Date(reportData.ratesVlcc[1]?.date))}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-US', { style: 'decimal'}).format(reportData.ratesVlcc[1]?.dollar_rate)}
                  </td>
                    {colorCell(reportData.ratesVlcc[1]?.dollar_rate - reportData.prevRatesVlcc[1]?.dollar_rate,0)}
                </tr>  */}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportData?.ratesDirty?.[0]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[0]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[0]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[0]?.ws_rate -
                          reportData?.prevRatesDirty?.[0]?.ws_rate
                      )}
                    </tr>
                    <tr>
                      <td>{reportData?.ratesDirty?.[1]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[1]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[1]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[1]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[1]?.ws_rate -
                          reportData?.prevRatesDirty?.[1]?.ws_rate
                      )}
                    </tr>
                    <tr>
                      <td>{reportData?.ratesDirty?.[2]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[2]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[2]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[2]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[2]?.ws_rate -
                          reportData?.prevRatesDirty?.[2]?.ws_rate
                      )}
                    </tr>
                    <tr>
                      <td>{reportData?.ratesDirty?.[3]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[3]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[3]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[3]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[3]?.ws_rate -
                          reportData?.prevRatesDirty?.[3]?.ws_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>VLCC NB</td>
                      <td>
                        {reportData.ratesNewbuilding[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.ratesNewbuilding[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesNewbuilding[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>VLCC 5YO</td>
                      <td>
                        {reportData.ratesSpWet5[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpWet5[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpWet5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>VLCC 10YO</td>
                      <td>
                        {reportData.ratesSpWet10[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpWet10[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpWet10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export const SuezTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(
          `/rate-tables?segment=Suezmax`
        );
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 Year T/C</td>
                      <td>
                        {reportData.rates1yeartc[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.rates1yeartc[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartc[0]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartc[0]?.dollar_rate -
                          reportData.prevRates1yearTc[0]?.dollar_rate,
                        0
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportData?.ratesDirty?.[0]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[0]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[0]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[0]?.ws_rate -
                          reportData?.prevRatesDirty?.[4]?.ws_rate
                      )}
                    </tr>
                    <tr>
                      <td>{reportData?.ratesDirty?.[1]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[1] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[1]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[1]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[1]?.ws_rate -
                          reportData?.prevRatesDirty?.[1]?.ws_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Suezmax NB</td>
                      <td>
                        {reportData.ratesNewbuilding[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.ratesNewbuilding[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesNewbuilding[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Suezmax 5YO</td>
                      <td>
                        {reportData.ratesSpWet5[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpWet5[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpWet5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Suezmax 10YO</td>
                      <td>
                        {reportData.ratesSpWet10[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpWet10[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpWet10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export const AfraTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(
          `/rate-tables?segment=Aframax`
        );
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 Year T/C</td>
                      <td>
                        {reportData.rates1yeartc[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.rates1yeartc[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartc[0]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartc[0]?.dollar_rate -
                          reportData.prevRates1yearTc[0]?.dollar_rate,
                        0
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportData?.ratesDirty?.[0]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[0]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[0]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[0]?.ws_rate -
                          reportData?.prevRatesDirty?.[0]?.ws_rate
                      )}
                    </tr>
                    <tr>
                      <td>{reportData?.ratesDirty?.[1]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[1] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[1]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[1]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[1]?.ws_rate -
                          reportData?.prevRatesDirty?.[1]?.ws_rate
                      )}
                    </tr>
                    <tr>
                      <td>{reportData?.ratesDirty?.[2]?.route.toString()}</td>
                      <td>
                        {reportData?.ratesDirty?.[2] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData?.ratesDirty?.[2]?.date)
                          )}
                      </td>
                      <td>
                        WS
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData?.ratesDirty?.[2]?.ws_rate)}
                      </td>
                      {colorCell(
                        reportData?.ratesDirty?.[2]?.ws_rate -
                          reportData?.prevRatesDirty?.[2]?.ws_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Aframax NB</td>
                      <td>
                        {reportData.ratesNewbuilding[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.ratesNewbuilding[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesNewbuilding[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Aframax 5YO</td>
                      <td>
                        {reportData.ratesSpWet5[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpWet5[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpWet5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Aframax 10YO</td>
                      <td>
                        {reportData.ratesSpWet10[0] &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpWet10[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpWet10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
