import { fetchWithSession } from 'components/fetchWithSession';
import { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';

function colorCell(content: number, decimals: number = 0): JSX.Element {
  return content > 0 ? (
    <td data-label="Change" style={{ color: 'green' }}>
      {'+' +
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(content)}
    </td>
  ) : content < 0 ? (
    <td data-label="Change" style={{ color: 'red' }}>
      {new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(content)}
    </td>
  ) : (
    <td data-label="Change">-</td>
  );
}

export const CapeTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(
          `/rate-tables?segment=Capesize`
        );
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row xs={1} md={2} lg={3}>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="rate-table bunker-future-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>1 Year T/C</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="1 Year T/C">
                        {reportData.rates1yeartcBulk[0]?.route.toString()}
                      </td>
                      <td data-label="Date" style={{ whiteSpace: 'nowrap' }}>
                        {reportData.rates1yeartcBulk[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[0]?.date)
                          )}
                      </td>
                      <td data-label="Value">
                        {reportData.rates1yeartcBulk[0]?.dollar_rate &&
                          new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(
                            reportData.rates1yeartcBulk[0]?.dollar_rate
                          )}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[0]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[0]?.dollar_rate
                      )}
                    </tr>
                    <tr>
                      <td data-label="1 Year T/C">
                        {reportData.rates1yeartcBulk[1]?.route.toString()}
                      </td>
                      <td data-label="Date" style={{ whiteSpace: 'nowrap' }}>
                        {reportData.rates1yeartcBulk[1]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[1]?.date)
                          )}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartcBulk[1]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[1]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[1]?.dollar_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Rate">TCE Cont/Far East</td>
                      <td data-label="Date">
                        {new Intl.DateTimeFormat('en-US', {
                          year: '2-digit',
                          month: 'short',
                          day: 'numeric',
                        }).format(new Date(reportData.rateCapesize1?.date))}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rateCapesize1.value)}
                      </td>
                      {colorCell(
                        reportData.rateCapesize1.value -
                          reportData.rateCapesize1Prev.value
                      )}
                    </tr>
                    <tr>
                      <td data-label="Rate">Australia/China</td>
                      <td data-label="Date">
                        {new Intl.DateTimeFormat('en-US', {
                          year: '2-digit',
                          month: 'short',
                          day: 'numeric',
                        }).format(new Date(reportData.rateCapesize2?.date))}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(reportData.rateCapesize2.value)}
                      </td>
                      {colorCell(
                        reportData.rateCapesize2.value -
                          reportData.rateCapesize2Prev.value,
                        2
                      )}
                    </tr>
                    <tr>
                      <td data-label="Rate">Pacific RV</td>
                      <td data-label="Date">
                        {new Intl.DateTimeFormat('en-US', {
                          year: '2-digit',
                          month: 'short',
                          day: 'numeric',
                        }).format(new Date(reportData.rateCapesize3?.date))}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rateCapesize3?.value)}
                      </td>
                      {colorCell(
                        reportData.rateCapesize3.value -
                          reportData.rateCapesize3Prev.value
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Snp/NB">Newcastlemax NB</td>
                      <td data-label="Date">
                        {reportData.ratesNewbuilding[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.ratesNewbuilding[0]?.date)
                          )}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesNewbuilding[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td data-label="Snp/NB">Capesize 5YO</td>
                      <td data-label="Date">
                        {reportData.ratesSpDry5[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry5[0]?.date))}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td data-label="Snp/NB">Capesize 10YO</td>
                      <td data-label="Date">
                        {reportData.ratesSpDry10[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry10[0]?.date))}
                      </td>
                      <td data-label="Value">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export const PmxTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(
          `/rate-tables?segment=Panamax`
        );
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row xs={1} md={2} lg={3}>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>1 Year T/C</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {reportData.rates1yeartcBulk[0]?.route.toString()}
                      </td>
                      <td>
                        {reportData.rates1yeartcBulk[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartcBulk[0]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[0]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[0]?.dollar_rate
                      )}
                    </tr>
                    <tr>
                      <td>
                        {reportData.rates1yeartcBulk[1]?.route.toString()}
                      </td>
                      <td>
                        {reportData.rates1yeartcBulk[1]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[1]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartcBulk[1]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[1]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[1]?.dollar_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Transatlantic RV</td>
                      <td>
                        {reportData.ratePanamax1?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratePanamax1?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.ratePanamax1?.value)}
                      </td>
                      {colorCell(
                        reportData.ratePanamax1.value -
                          reportData.ratePanamax1Prev?.value
                      )}
                    </tr>
                    <tr>
                      <td>TCE Cont/Far East</td>
                      <td>
                        {reportData.ratePanamax2?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratePanamax2?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.ratePanamax2?.value)}
                      </td>
                      {colorCell(
                        reportData.ratePanamax2.value -
                          reportData.ratePanamax2Prev?.value
                      )}
                    </tr>
                    <tr>
                      <td>TCE Far East/Cont</td>
                      <td>
                        {reportData.ratePanamax3?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratePanamax3?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.ratePanamax3?.value)}
                      </td>
                      {colorCell(
                        reportData.ratePanamax3.value -
                          reportData.ratePanamax3Prev?.value
                      )}
                    </tr>
                    <tr>
                      <td>TCE Far East RV</td>
                      <td>
                        {reportData.ratePanamax4?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratePanamax4?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.ratePanamax4?.value)}
                      </td>
                      {colorCell(
                        reportData.ratePanamax4.value -
                          reportData.ratePanamax4Prev?.value
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Kamsarmax NB</td>
                      <td>
                        {reportData.ratesNewbuilding[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.ratesNewbuilding[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesNewbuilding[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Kamsarmax 5YO</td>
                      <td>
                        {reportData.ratesSpDry5[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry5[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Kamsarmax 10YO</td>
                      <td>
                        {reportData.ratesSpDry10[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry10[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export const SupraTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(
          `/rate-tables?segment=Supra/Ultramax`
        );
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row xs={1} md={2} lg={3}>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>1 Year T/C</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {reportData.rates1yeartcBulk[0]?.route.toString()}
                      </td>
                      <td>
                        {reportData.rates1yeartcBulk[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartcBulk[0]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[0]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[0]?.dollar_rate
                      )}
                    </tr>
                    <tr>
                      <td>
                        {reportData.rates1yeartcBulk[1]?.route.toString()}
                      </td>
                      <td>
                        {reportData.rates1yeartcBulk[1]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[1]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartcBulk[1]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[1]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[1]?.dollar_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Atlantic RV</td>
                      <td>
                        {reportData.rateSupramax1?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.rateSupramax1?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rateSupramax1?.value)}
                      </td>
                      {colorCell(
                        reportData.rateSupramax1?.value -
                          reportData.rateSupramax1Prev?.value
                      )}
                    </tr>
                    <tr>
                      <td>Pacific RV</td>
                      <td>
                        {reportData.rateSupramax2?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.rateSupramax2?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rateSupramax2?.value)}
                      </td>
                      {colorCell(
                        reportData.rateSupramax2?.value -
                          reportData.rateSupramax2Prev?.value
                      )}
                    </tr>
                    <tr>
                      <td>TCE Cont/Far East</td>
                      <td>
                        {reportData.rateSupramax3?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.rateSupramax3?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rateSupramax3?.value)}
                      </td>
                      {colorCell(
                        reportData.rateSupramax3?.value -
                          reportData.rateSupramax3Prev?.value
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ultramax NB</td>
                      <td>
                        {reportData.ratesNewbuilding[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.ratesNewbuilding[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesNewbuilding[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Ultramax 5YO</td>
                      <td>
                        {reportData.ratesSpDry5[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry5[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Ultramax 10YO</td>
                      <td>
                        {reportData.ratesSpDry10[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry10[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
export const HandysizeTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(
          `/rate-tables?segment=Handysize`
        );
        const reportJson = await reportUrl.json();
        setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      fetchReport();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div>
      <Row xs={1} md={2} lg={3}>
        {reportData && (
          <>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>1 Year T/C</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {reportData.rates1yeartcBulk[0]?.route.toString()}
                      </td>
                      <td>
                        {reportData.rates1yeartcBulk[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(
                            new Date(reportData.rates1yeartcBulk[0]?.date)
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(reportData.rates1yeartcBulk[0]?.dollar_rate)}
                      </td>
                      {colorCell(
                        reportData.rates1yeartcBulk[0]?.dollar_rate -
                          reportData.prevRates1YearTCBulk[0]?.dollar_rate
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
                <Table
                  className="bunker-future-table rate-table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>SnP/NB</th>
                      <th>Date</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Handysize 5YO</td>
                      <td>
                        {reportData.ratesSpDry5[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry5[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry5[0]?.dollar_rate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Handysize 10YO</td>
                      <td>
                        {reportData.ratesSpDry10[0]?.date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(reportData.ratesSpDry10[0]?.date))}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(reportData.ratesSpDry10[0]?.dollar_rate)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
