import { fetchWithSession } from "components/fetchWithSession";
import { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap"

function colorCell(content:number, decimals:number=0):JSX.Element{
  return (content > 0) ? <td style={{color:"green"}}>{'+'+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(content)}</td> : (content < 0) ? <td style={{color:"red"}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(content)}</td> : <td>-</td>
}

export const LNGTables = () => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const reportUrl = await fetchWithSession(`/rate-tables?segment=LNGC`);
      const reportJson = await reportUrl.json();
      setReportData(reportJson.msg);
      } catch (error) {
        console.log(error);
      }
      
    }
    try {
      fetchReport();
    } catch (error) {	
      console.log(error);
    }
  }, []);
    return (
        <div>
        <Row>
        {reportData &&
        <>
          <Col>
          <div>
            <Table className="bunker-future-table rate-table" striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Value</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {reportData?.tc1yr?.[0]?.route.toString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}}>
                    {reportData?.tc1yr?.[0]?.date && new Intl.DateTimeFormat('en-US', { year: "2-digit", month: "short",day: "numeric"}).format(new Date(reportData?.tc1yr?.[0]?.date))}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(reportData?.tc1yr?.[0]?.dollar_rate)}
                  </td>
                    {colorCell(reportData?.delta3)}
                </tr>
              </tbody>
              </Table>
              </div>
            </Col>
            <Col>
            <div>
            <Table className="bunker-future-table rate-table" striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Value</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {reportData?.eastSuez?.[0]?.route.toString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}}>
                    {reportData?.eastSuez?.[0]?.date && new Intl.DateTimeFormat('en-US', { year: "2-digit", month: "short",day: "numeric"}).format(new Date(reportData?.eastSuez?.[0]?.date))}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(reportData?.eastSuez?.[0]?.dollar_rate)}
                  </td>
                    {colorCell(reportData?.delta1)}
                </tr>
                <tr>
                  <td>
                    {reportData?.westSuez?.[0]?.route.toString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}}>
                    {reportData?.westSuez?.[0]?.date && new Intl.DateTimeFormat('en-US', { year: "2-digit", month: "short",day: "numeric"}).format(new Date(reportData?.westSuez?.[0]?.date))}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(reportData?.westSuez?.[0]?.dollar_rate)}
                  </td>
                    {colorCell(reportData?.delta2)}
                </tr>
              </tbody>
              </Table>
              </div>
            </Col>
            <Col>
            <div>
            <Table className="bunker-future-table rate-table" striped bordered hover responsive>
            <thead>
                <tr>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Value</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {reportData?.ratesNewbuilding?.[0]?.route.toString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}}>
                    {reportData?.ratesNewbuilding?.[0]?.date && new Intl.DateTimeFormat('en-US', { year: "2-digit", month: "short",day: "numeric"}).format(new Date(reportData?.ratesNewbuilding?.[0]?.date))}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 1, maximumFractionDigits: 1}).format(reportData?.ratesNewbuilding?.[0]?.dollar_rate)}
                  </td>
                    {colorCell(reportData?.ratesNewbuilding?.[0]?.dollar_rate - reportData?.prevRatesNewbuilding?.[0]?.dollar_rate,1)}
                </tr>
              </tbody>
              </Table>
              </div>
            </Col>
          </>
          }
          </Row>
        </div>
    )
}