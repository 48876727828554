import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';

import { AFSpinner } from './AFSpinner';
import { Props, MetricResponse } from './RateData';
import { fetchWithSession } from './fetchWithSession';
HighchartsExporting(Highcharts);
export const GetRateData = (props: Props) => {
  const [rateData, setRateData] = useState<MetricResponse[]>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentYear, setCurrentYear] = useState<number>(2022);

  async function getRateData(segment: string) {
    const response = await fetchWithSession(`/rate-values?segment=${segment}`);
    return await response.json();
  }
  useEffect(() => {
    setDataLoaded(false);
    let dt = new Date();
    setCurrentYear(dt.getFullYear());
    getRateData(props.segment)
      .then((rates) => {
        setRateData(rates.response);
        setDataLoaded(true);
      })
      .catch((error) => console.warn('error', error));
  }, [props]);

  return (
    <div id={`${props.segment}-charts`}>
      <Container fluid>
        {!dataLoaded && <AFSpinner />}
        <div>
          <Row xs={1} md={3} lg={3}>
            {rateData &&
              dataLoaded &&
              rateData.map((e, k) => (
                <Col key={k} style={{ marginBottom: '20px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      exporting: {
                        fetchOptions: {
                          headers: {
                            referer: 'https://fearnportal.com',
                          },
                        },
                        buttons: {
                          contextButton: {
                            menuItems: [
                              'downloadPNG',
                              'separator',
                              'downloadXLS',
                            ],
                            theme: {
                              fill: '#142E3D',
                            },
                            symbolFill: '#000000',
                          },
                        },
                        chartOptions: {
                          title: {
                            style: {
                              color: 'black', // black title
                            },
                          },
                          subtitle: {
                            text: '',
                            style: {
                              color: 'black',
                            },
                          },
                          plotOptions: {
                            pie: {
                              dataLabels: {
                                enabled: true,
                                color: 'black',
                              },
                            },
                            column: {
                              dataLabels: {
                                enabled: true,
                                color: 'black',
                              },
                            },
                          },
                          chart: {
                            backgroundColor: {
                              linearGradient: [0, 0, 0, 400],
                              stops: [
                                [0, '#F0F0F0'],
                                [1, '#FFFFFF'],
                              ],
                            },
                            borderWidth: 0,
                            borderRadius: 15,
                            plotBackgroundColor: null,
                            plotShadow: false,
                            plotBorderWidth: 0,
                          },
                          legend: {
                            itemStyle: {
                              color: '#CCC',
                            },
                            itemHoverStyle: {
                              color: '#FFF',
                            },
                            itemHiddenStyle: {
                              color: '#333',
                            },
                          },
                        },
                      },
                      chart: {
                        type: 'spline',
                        zoomType: 'x',
                        backgroundColor: '#142E3D',
                        borderWidth: 0,
                        borderRadius: 0,
                        plotBackgroundColor: null,
                        plotShadow: false,
                        plotBorderWidth: 0,
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.y}',
                            color: '#ffffff',
                          },
                        },
                        column: {
                          borderWidth: 0,
                          pointPadding: 0,
                          stacking: 'stream',
                          dataLabels: {
                            enabled: false,
                          },
                        },
                        line: {
                          dataLabels: {
                            color: '#CCC',
                          },
                          marker: {
                            lineColor: '#333',
                          },
                        },
                        spline: {
                          marker: {
                            lineColor: '#333',
                          },
                        },
                      },
                      colors: [
                        '#5787b2',
                        '#95D2CA',
                        '#D46C75',
                        '#8A84FF',
                        '#7FC2FB',
                        '#206273',
                        '#D46C75',
                        '#bfbfbf',
                        '#997D87',
                        '#0000A6',
                        '#63FFAC',
                        '#FF4A46',
                        '#5A0007',
                        '#809693',
                        '#FEFFE6',
                        '#1B4400',
                        '#4FC601',
                        '#3B5DFF',
                        '#4A3B53',
                        '#FF2F80',
                        '#61615A',
                        '#BA0900',
                        '#6B7900',
                        '#00C2A0',
                        '#FFAA92',
                        '#FF90C9',
                        '#B903AA',
                        '#D16100',
                        '#DDEFFF',
                        '#000035',
                        '#7B4F4B',
                        '#A1C299',
                      ],
                      xAxis: {
                        gridLineWidth: 0,
                        lineColor: '#999',
                        tickColor: '#999',
                        labels: {
                          style: {
                            color: '#999',
                            fontWeight: 'bold',
                          },
                        },
                        type: 'datetime',
                        title: {
                          style: {
                            color: '#AAA',
                            font: 'bold 12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                          },
                        },
                      },
                      toolbar: {
                        itemStyle: {
                          color: '#CCC',
                        },
                      },
                      legend: {
                        itemStyle: {
                          color: '#CCC',
                          fontSize: '9px',
                          font: '9pt Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                        },
                        itemHoverStyle: {
                          color: '#FFF',
                        },
                        itemHiddenStyle: {
                          color: '#333',
                        },
                      },
                      credits: {
                        style: {
                          fontSize: '15px',
                        },
                        enabled: true,
                        text: `© ${currentYear} Fearnleys`,
                        href: 'https://www.fearnleys.com',
                        position: {
                          align: 'left',
                          verticalAlign: 'top',
                          x: 100,
                          y: 100,
                        },
                      },
                      series: e.data.map(function (elem) {
                        return {
                          data: elem.timeSeries.map((element) => [
                            new Date(element.date).getTime(),
                            element.value
                              ? element.value
                              : Object.values(elem.timeSeries[0]),
                          ]),
                          name: elem.name
                            ? elem.name
                            : Object.keys(elem.timeSeries[0]),
                          yAxis: elem.yAxis ? elem.yAxis : 0,
                          connectNulls: e.header
                            .split(' ')
                            .includes('(rolling)')
                            ? true
                            : false,
                        };
                      }),
                      title: {
                        text: e.header,
                        style: {
                          color: '#FFF',
                          font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                        },
                      },
                      yAxis: [
                        {
                          // Primary yAxis
                          alternateGridColor: null,
                          minorTickInterval: null,
                          gridLineColor: 'rgba(255, 255, 255, .1)',
                          lineWidth: 0,
                          tickWidth: 0,
                          labels: {
                            style: {
                              color: '#999',
                              fontWeight: 'bold',
                            },
                          },
                          title: {
                            text: 'value',
                          },
                        },
                        {
                          // Secondary yAxis
                          alternateGridColor: null,
                          minorTickInterval: null,
                          gridLineColor: 'rgba(255, 255, 255, .1)',
                          lineWidth: 0,
                          tickWidth: 0,
                          labels: {
                            style: {
                              color: '#999',
                              fontWeight: 'bold',
                            },
                          },
                          title: {
                            text: 'value',
                          },
                          opposite: true,
                        },
                      ],
                    }}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};
