export function makeScriptTag1_LPG() {
  const scriptTag1 = document.createElement('script');
  scriptTag1.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag1.async = true;
  scriptTag1.innerHTML = `
          {
            "symbol": "TVC:USOIL",
      "width": "100%",
      "height": "220",
      "locale": "en",
      "dateRange": "12M",
      "colorTheme": "dark",
      "trendLineColor": "#37a6ef",
      "underLineColor": "rgba(55, 166, 239, 0.15)",
      "isTransparent": false,
      "autosize": false,
      "largeChartUrl": ""
          }
          `;
  return scriptTag1;
}
