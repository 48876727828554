import { SegmentMatch } from '../../../state/segment';
import { spawnTwitter } from '../getTwitterFeedUrlFunc';
import { makeScriptTag1_LPG } from './makeScriptTag1_LPG';
import { MakeScriptTag2_LPG } from './makeScriptTag2_LPG';
import { makeScriptTag3_LPG } from './makeScriptTag3_LPG';
import { makeScriptTag_LPG } from './makeScriptTag_LPG';
import { runScriptTagTicker2_LPG } from './runScriptTagTicker2_LPG';
import { runScriptTagTicker_LPG } from './runScriptTagTicker_LPG';
import {
  ScriptTag1_DryCargo,
  ScriptTag3_DryCargo,
  ScriptTag4_DryCargo,
  scriptTagTicker2_DryCargo,
  scriptTagTicker_DryCargo,
  ScriptTag_DryCargo,
} from './thirdPartyJS';

export function loadIndexJSScripts(segment: string) {
  // Do all actual loading inside the try-catch block because switching swiftly back and forth between tabs can create issues
  try {
    spawnTwitter(segment);

    if (segment === SegmentMatch.LNG) {
      const scriptTag = document.createElement('script');
      scriptTag.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag.async = true;
      scriptTag.innerHTML = `
          {
            "symbol": "TVC:UKOIL",
        "width": "100%",
        "height": "220",
        "locale": "en",
        "dateRange": "12M",
        "colorTheme": "dark",
        "trendLineColor": "#37a6ef",
        "underLineColor": "rgba(55, 166, 239, 0.15)",
        "isTransparent": false,
        "autosize": false,
        "largeChartUrl": ""
          }
          `;
      document
        .getElementsByClassName('tradingview-widget-container-1')[0]
        .append(scriptTag);

      const scriptTag1 = document.createElement('script');
      scriptTag1.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag1.async = true;
      scriptTag1.innerHTML = `
            {
              "symbol": "TVC:USOIL",
        "width": "100%",
        "height": "220",
        "locale": "en",
        "dateRange": "12M",
        "colorTheme": "dark",
        "trendLineColor": "#37a6ef",
        "underLineColor": "rgba(55, 166, 239, 0.15)",
        "isTransparent": false,
        "autosize": false,
        "largeChartUrl": ""
            }
            `;
      document
        .getElementsByClassName('tradingview-widget-container-2')[0]
        .append(scriptTag1);

      const scriptTag2 = document.createElement('script');
      scriptTag2.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag2.async = true;
      scriptTag2.innerHTML = `
            {
              "symbol": "INDEX:DXY",
        "width": "100%",
        "height": "220",
        "locale": "en",
        "dateRange": "12M",
        "colorTheme": "dark",
        "trendLineColor": "#37a6ef",
        "underLineColor": "rgba(55, 166, 239, 0.15)",
        "isTransparent": false,
        "autosize": false,
        "largeChartUrl": ""
            }
            `;
      document
        .getElementsByClassName('tradingview-widget-container-3')[0]
        .append(scriptTag2);

      const scriptTag3 = document.createElement('script');
      scriptTag3.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag3.async = true;
      scriptTag3.innerHTML = `
              {
                "symbol": "NASDAQ:CME",
         "width": "100%",
         "height": "220",
         "locale": "en",
         "dateRange": "12M",
         "colorTheme": "dark",
         "trendLineColor": "#37a6ef",
         "underLineColor": "rgba(55, 166, 239, 0.15)",
         "isTransparent": false,
         "autosize": false,
         "largeChartUrl": ""
              }
              `;
      document
        .getElementsByClassName('tradingview-widget-container-4')[0]
        .append(scriptTag3);

      const scriptTagTicker = document.createElement('script');
      scriptTagTicker.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
      scriptTagTicker.async = true;
      scriptTagTicker.innerHTML = `
            {
              "symbols": [
                {
                  "title": "Brent",
                  "proName": "TVC:UKOIL"
                },
                {
                  "title": "USD/NOK",
                  "proName": "FX_IDC:USDNOK"
                },
                {
                  "title": "USD/EUR",
                  "proName": "FX_IDC:USDEUR"
                },
                {
                  "title": "USD/KRW",
                  "proName": "FX_IDC:USDKRW"
                },
                {
                  "title": "USD/JPY",
                  "proName": "OANDA:USDJPY"
                }
              ],
              "colorTheme": "dark",
              "isTransparent": true,
              "locale": "en"
            }
            `;
      document
        .getElementsByClassName('tradingview-widget-container-5')[0]
        .append(scriptTagTicker);

      const scriptTagTicker2 = document.createElement('script');
      scriptTagTicker2.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
      scriptTagTicker2.async = true;
      scriptTagTicker2.innerHTML = `
              {
        "colorTheme": "dark",
        "dateRange": "12M",
        "showChart": false,
        "locale": "en",
        "width": "100%",
        "height": "450",
        "largeChartUrl": "",
        "isTransparent": false,
        "showSymbolLogo": true,
        "tabs": [
        {
         "title": "Commodities",
         "symbols": [
           {
             "s": "CAPITALCOM:NATURALGAS",
             "d": "Natural Gas"
           },
           {
            "s": "TVC:UKOIL",
            "d": "Brent Crude Oil"
           },
           {
             "s": "NYMEX:NG1!",
             "d": "Natural Gas Futures"
           },
           {
            "s": "NYMEX:NG2!",
            "d": "Natural Gas Futures Next"
           }
         ],
         "originalTitle": "Commodities"
        },
        {
         "title": "Indices",
         "symbols": [
          {
            "s": "INDEX:OBX",
            "d": "Oslo Børs"
          },
          {
            "s": "CURRENCYCOM:US500",
            "d": "S&P 500"
          },
          {
            "s": "CURRENCYCOM:US100",
            "d": "Nasdaq"
          },
          {
            "s": "XETR:DAX",
            "d": "DAX"
          },
          {
            "s": "CAPITALCOM:UK100",
            "d": "FTSE 100"
          },
          {
            "s": "OTC:ISMUF",
            "d": "MSCI China"
          },
          {
            "s": "HSI:HSI",
            "d": "Hang Seng"
          },
          {
            "s": "index:sti",
            "d": "Straits Times Index"
          },
          {
            "s": "CAPITALCOM:J225",
            "d": "Nikkei 225"
          }
        ],
        "originalTitle": "Bonds"
        },
        {
         "title": "Stocks",
         "symbols": [
           {
             "s": "NYSE:FLNG",
             "d": "Flex LNG"
           },
           {
             "s": "NYSE:GLOG",
             "d": "Gaslog"
           },
           {
            "s": "NYSE:GLOP",
            "d": "Gaslog Partners"
           },
           {
             "s": "NASDAQ:GLNG",
             "d": "Golar LNG"
           },
           {
             "s": "NASDAQ:GMLP",
             "d": "Golar LNG Partners"
           },
           {
             "s": "NYSE:HMLP",
             "d": "Hoegh LNG Partners"
           },
           {
             "s": "NYSE:TGP",
             "d": "Teekay LNG Partners"
           },
           {
             "s": "NASDAQ:TELL",
             "d": "Tellurian"
           },
           {
             "s": "NASDAQ:NFE",
             "d": "New Fortress Energy"
           },
           {
             "s": "KRX:010140",
             "d": "Samsung HVY IND"
           },
           {
             "s": "OTC:YSHLF",
             "d": "Yangzijiang Shipbuilding"
           }
         ],
         "originalTitle": "Forex"
        }
        ]
        }
              `;
      document
        .getElementsByClassName('tradingview-widget-container-6')[0]
        .append(scriptTagTicker2);

      // end if LNG
    } else if (segment === SegmentMatch.LPG) {
      const scriptTag = makeScriptTag_LPG();
      document
        .getElementsByClassName('tradingview-widget-container-1')[0]
        .append(scriptTag);

      const scriptTag1 = makeScriptTag1_LPG();
      document
        .getElementsByClassName('tradingview-widget-container-2')[0]
        .append(scriptTag1);

      const scriptTag2 = MakeScriptTag2_LPG();
      document
        .getElementsByClassName('tradingview-widget-container-3')[0]
        .append(scriptTag2);

      const scriptTag3 = makeScriptTag3_LPG();
      document
        .getElementsByClassName('tradingview-widget-container-4')[0]
        .append(scriptTag3);

      const scriptTagTicker = runScriptTagTicker_LPG();
      document
        .getElementsByClassName('tradingview-widget-container-5')[0]
        .append(scriptTagTicker);

      const scriptTagTicker2 = runScriptTagTicker2_LPG();
      document
        .getElementsByClassName('tradingview-widget-container-6')[0]
        .append(scriptTagTicker2);

      // end if LPG
    } else if (segment === SegmentMatch.Tankers) {
      const scriptTag = document.createElement('script');
      scriptTag.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag.async = true;
      scriptTag.innerHTML = `
      {
        "symbol": "TVC:UKOIL",
    "width": "100%",
    "height": "220",
    "locale": "en",
    "dateRange": "12M",
    "colorTheme": "dark",
    "trendLineColor": "#37a6ef",
    "underLineColor": "rgba(55, 166, 239, 0.15)",
    "isTransparent": false,
    "autosize": false,
    "largeChartUrl": ""
      }
      `;
      document
        .getElementsByClassName('tradingview-widget-container-1')[0]
        .append(scriptTag);

      const scriptTag1 = document.createElement('script');
      scriptTag1.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag1.async = true;
      scriptTag1.innerHTML = `
        {
          "symbol": "TVC:USOIL",
    "width": "100%",
    "height": "220",
    "locale": "en",
    "dateRange": "12M",
    "colorTheme": "dark",
    "trendLineColor": "#37a6ef",
    "underLineColor": "rgba(55, 166, 239, 0.15)",
    "isTransparent": false,
    "autosize": false,
    "largeChartUrl": ""
        }
        `;
      document
        .getElementsByClassName('tradingview-widget-container-2')[0]
        .append(scriptTag1);

      const scriptTag2 = document.createElement('script');
      scriptTag2.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag2.async = true;
      scriptTag2.innerHTML = `
        {
          "symbol": "INDEX:DXY",
    "width": "100%",
    "height": "220",
    "locale": "en",
    "dateRange": "12M",
    "colorTheme": "dark",
    "trendLineColor": "#37a6ef",
    "underLineColor": "rgba(55, 166, 239, 0.15)",
    "isTransparent": false,
    "autosize": false,
    "largeChartUrl": ""
        }
        `;
      document
        .getElementsByClassName('tradingview-widget-container-3')[0]
        .append(scriptTag2);

      const scriptTag3 = document.createElement('script');
      scriptTag3.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      scriptTag3.async = true;
      scriptTag3.innerHTML = `
          {
            "symbol": "NASDAQ:CME",
     "width": "100%",
     "height": "220",
     "locale": "en",
     "dateRange": "12M",
     "colorTheme": "dark",
     "trendLineColor": "#37a6ef",
     "underLineColor": "rgba(55, 166, 239, 0.15)",
     "isTransparent": false,
     "autosize": false,
     "largeChartUrl": ""
          }
          `;
      document
        .getElementsByClassName('tradingview-widget-container-4')[0]
        .append(scriptTag3);

      const scriptTagTicker = document.createElement('script');
      scriptTagTicker.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
      scriptTagTicker.async = true;
      scriptTagTicker.innerHTML = `
        {
          "symbols": [
            {
              "title": "Brent",
              "proName": "TVC:UKOIL"
            },
            {
              "title": "USD/NOK",
              "proName": "FX_IDC:USDNOK"
            },
            {
              "title": "USD/EUR",
              "proName": "FX_IDC:USDEUR"
            },
            {
              "title": "USD/KRW",
              "proName": "FX_IDC:USDKRW"
            },
            {
              "title": "USD/JPY",
              "proName": "OANDA:USDJPY"
            }
          ],
          "colorTheme": "dark",
          "isTransparent": true,
          "locale": "en"
        }
        `;
      document
        .getElementsByClassName('tradingview-widget-container-5')[0]
        .append(scriptTagTicker);

      const scriptTagTicker2 = document.createElement('script');
      scriptTagTicker2.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
      scriptTagTicker2.async = true;
      scriptTagTicker2.innerHTML = `
          {
    "colorTheme": "dark",
    "dateRange": "12M",
    "showChart": false,
    "locale": "en",
    "width": "100%",
    "height": "450",
    "largeChartUrl": "",
    "isTransparent": false,
    "showSymbolLogo": true,
    "tabs": [
    {
     "title": "Commodities",
     "symbols": [
      {
        "s": "TVC:UKOIL",
        "d": "Brent Crude Oil"
      },
      {
        "s": "TVC:USOIL",
        "d": "WTI Crude Oil"
      },
      {
        "s": "COMEX:HG1!",
        "d": "Copper"
      },
      {
        "s": "CURRENCYCOM:GOLD",
        "d": "Gold"
      },
      {
        "s": "NYMEX:YF1!",
        "d": "Heating Oil Platts futures"
      },
      {
        "s": "NYMEX:RB1!",
        "d": "RBOB Gasoline Futures"
      }
     ],
     "originalTitle": "Commodities"
    },
    {
     "title": "Indices",
     "symbols": [
      {
        "s": "INDEX:OBX",
        "d": "Oslo Børs"
      },
      {
        "s": "CURRENCYCOM:US500",
        "d": "S&P 500"
      },
      {
        "s": "CURRENCYCOM:US100",
        "d": "Nasdaq"
      },
      {
        "s": "XETR:DAX",
        "d": "DAX"
      },
      {
        "s": "CAPITALCOM:UK100",
        "d": "FTSE 100"
      },
      {
        "s": "OTC:ISMUF",
        "d": "MSCI China"
      },
      {
        "s": "HSI:HSI",
        "d": "Hang Seng"
      },
      {
        "s": "index:sti",
        "d": "Straits Times Index"
      },
      {
        "s": "CAPITALCOM:J225",
        "d": "Nikkei 225"
      }
    ],
    "originalTitle": "Bonds"
    },
    {
     "title": "Stocks",
     "symbols": [
       {
         "s": "NYSE:FRO",
         "d": "Frontline US"
       },
       {
         "s": "NYSE:STNG",
         "d": "Scorpio Tankers"
       },
       {
        "s": "NYSE:NAT",
        "d": "Nordic American Tankers"
       },
       {
         "s": "NYSE:EURN",
         "d": "Euronav US"
       },
       {
        "s": "SWB:OCW",
        "d": "Euronav"
       },
       {
         "s": "NYSE:DHT",
         "d": "DHT Holdings"
       },
       {
        "s": "NYSE:TNK",
        "d": "Teekay Tankers"
      },
      {
        "s": "OTC:OKENF",
        "d": "Okeanis Eco Tanker"
      },
      {
        "s": "OTC:HFIAF",
        "d": "Hafnia"
      },
      {
        "s": "CAPITALCOM:1919",
        "d": "Cosco Ship Hold CN"
      }
     ],
     "originalTitle": "Forex"
    }
    ]
    }
          `;
      document
        .getElementsByClassName('tradingview-widget-container-6')[0]
        .append(scriptTagTicker2); // end if Tankers
    } else if ((segment as string) === SegmentMatch.DryCargo) {
      ScriptTag_DryCargo();

      ScriptTag1_DryCargo();

      ScriptTag3_DryCargo();

      ScriptTag4_DryCargo();

      scriptTagTicker_DryCargo();

      scriptTagTicker2_DryCargo();

      // end Dry Cargo
    }
  } catch (err) {
    console.error('error on external js load');
    console.error(err);
  }
}
