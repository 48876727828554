import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';

import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { SegmentMatch } from '../state/segment';
import { AFSpinner } from './AFSpinner';
import { fetchWithSession } from 'components/fetchWithSession';
HighchartsExporting(Highcharts);

interface OverviewProps {
  segment: string;
}
interface DetailProps {
  segment: string;
  subsegment: string;
}
interface FleetOverview {
  Type: string;
  '# of Ships': number;
  CBM?: number;
  DWT?: number;
  'w/Scrubber': number;
  'Orderbook #': number;
  OrderbookDWT?: number;
  OrderbookCBM?: number;
}
interface FleetDevelopment {
  Date: Date;
  DevelopmentShips: number;
  YoYGrowth: number;
}
interface OrderBook {
  Date: Date;
  OrderbookShips: number;
}
interface YearlyStats {
  y: number;
  deliveries: number;
  deletions: number;
  launched: number;
  under_construction: number;
  keel_laid: number;
  scheduled: number;
  scrap_candidates: number;
}
interface MonthlyStats {
  month_start: Date;
  deliveries: number;
  deletions: number;
  launched: number;
  under_construction: number;
  keel_laid: number;
  scheduled: number;
}
interface AgeProfile {
  '0-4Y': number;
  '5-9Y': number;
  '10-14Y': number;
  '15-20Y': number;
  '+20Y': number;
}
interface LastOrders {
  Vesselname: string;
  IMO: number;
  Type: string;
  DWT?: number;
  CBM?: number;
  Built: Date;
  Ordered: Date;
  Shipbuilder: string;
  RegisteredOwner: string;
}
interface LastDeletions {
  Vesselname: string;
  IMO: number;
  Type: string;
  DWT?: number;
  CBM?: number;
  Built: Date;
  ScrapDate: Date;
}
interface LastDeliveries {
  Vesselname: string;
  IMO: number;
  Type: string;
  DWT?: number;
  CBM?: number;
  DeliveryDate: Date;
  Shipbuilder: string;
  RegisteredOwner: string;
}
interface FleetStatsOverview {
  fleetOverview: FleetOverview[];
  fleetDevelopment: FleetDevelopment[];
  yearlyStats: YearlyStats[];
  monthlyStats: MonthlyStats[];
  orderBook: OrderBook[];
  ageProfile: AgeProfile;
  devTableShips: any[];
  devTableDWT: any[];
}
interface FleetStatsDetails {
  fleetDevelopment: FleetDevelopment[];
  orderBook: OrderBook[];
  monthlyStats: MonthlyStats[];
  yearlyStats: YearlyStats[];
  ageProfile: AgeProfile;
  latestOrders: LastOrders[];
  latestDeletions: LastDeletions[];
  latestDeliveries: LastDeliveries[];
}

const highchartStyling = {
  exporting: {
    fetchOptions: {
      headers: {
        referer: 'https://fearnportal.com',
      },
    },
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'separator', 'downloadXLS'],
        theme: {
          fill: '#142E3D',
        },
        symbolFill: '#000000',
      },
    },
    chartOptions: {
      title: {
        style: {
          color: 'black', // black title
        },
      },
      subtitle: {
        text: '', // removes "click and drag" subtitle from exported chart
        style: {
          color: 'black',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            color: 'black',
          },
        },
        column: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%',
            color: 'black',
          },
        },
      },
      chart: {
        backgroundColor: {
          linearGradient: [0, 0, 0, 400],
          stops: [
            [0, '#F0F0F0'], // nice light background
            [1, '#FFFFFF'],
          ],
        },
        borderWidth: 0,
        borderRadius: 15,
        plotBackgroundColor: null,
        plotShadow: false,
        plotBorderWidth: 0,
      },
      legend: {
        itemStyle: {
          color: '#CCC',
        },
        itemHoverStyle: {
          color: '#FFF',
        },
        itemHiddenStyle: {
          color: '#333',
        },
      },
    },
  },
  chart: {
    zoomType: 'x',
    backgroundColor: '#142E3D',
    borderWidth: 0,
    borderRadius: 0,
    plotBackgroundColor: null,
    plotShadow: false,
    plotBorderWidth: 0,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
        color: '#ffffff',
      },
    },
    column: {
      borderWidth: 0,
      pointPadding: 0,
      stacking: 'stream',
      dataLabels: {
        enabled: false,
      },
    },
    line: {
      dataLabels: {
        color: '#CCC',
      },
      marker: {
        lineColor: '#333',
      },
    },
    spline: {
      marker: {
        lineColor: '#333',
      },
    },
  },
  colors: [
    '#5787b2',
    '#95D2CA',
    '#c1c0c0',
    '#8A84FF',
    '#7FC2FB',
    '#206273',
    '#D46C75',
    '#bfbfbf',
    '#997D87',
    '#0000A6',
    '#63FFAC',
    '#FF4A46',
    '#5A0007',
    '#809693',
    '#FEFFE6',
    '#1B4400',
    '#4FC601',
    '#3B5DFF',
    '#4A3B53',
    '#FF2F80',
    '#61615A',
    '#BA0900',
    '#6B7900',
    '#00C2A0',
    '#FFAA92',
    '#FF90C9',
    '#B903AA',
    '#D16100',
    '#DDEFFF',
    '#000035',
    '#7B4F4B',
    '#A1C299',
  ],
  xAxis: {
    gridLineWidth: 0,
    lineColor: '#999',
    tickColor: '#999',
    labels: {
      style: {
        color: '#999',
        fontWeight: 'bold',
      },
    },
    title: {
      style: {
        color: '#AAA',
        font: 'bold 12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      },
    },
  },
  toolbar: {
    itemStyle: {
      color: '#CCC',
    },
  },
  legend: {
    itemStyle: {
      color: '#CCC',
      fontSize: '9px',
      font: '9pt Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#333',
    },
  },
  credits: {
    style: {
      fontSize: '15px',
    },
    enabled: true,
    text: `© ${new Date().getFullYear()} Fearnleys`,
    href: 'https://www.fearnleys.com',
    position: {
      align: 'left',
      verticalAlign: 'top',
      x: 100,
      y: 100,
    },
  },
};

const GetFleetOverview = (props: OverviewProps) => {
  const [data, setData] = useState<FleetStatsOverview>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentYear, setCurrentYear] = useState<number>(2022);

  async function getFleetOverview(segment: string) {
    const response = await fetchWithSession(
      `/fleet-overview?segment=${segment}`
    );
    return await response.json();
  }
  useEffect(() => {
    setDataLoaded(false);
    let dt = new Date();
    setCurrentYear(dt.getFullYear());
    getFleetOverview(props.segment)
      .then((ais) => {
        setData(ais.response);
        setDataLoaded(true);
      })
      .catch((error) => console.warn('error', error));
  }, [props]);
  const overviewTable = (
    <Table className="fl-table" striped hover>
      <thead>
        <tr>
          <th>Type</th>
          <th style={{ textAlign: 'right' }}># of Ships</th>
          <th style={{ textAlign: 'right' }}>
            {['lpg', 'lng'].includes(props.segment) ? 'CBM' : 'DWT'}
          </th>
          <th style={{ textAlign: 'right' }}>w/Scrubber</th>
          <th style={{ textAlign: 'right' }}>Orderbook #</th>
          <th style={{ textAlign: 'right' }}>
            Orderbook{['lpg', 'lng'].includes(props.segment) ? 'CBM' : 'DWT'}
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.fleetOverview.map((e: FleetOverview, key) => (
          <tr key={key}>
            <td>{e.Type}</td>
            <td style={{ textAlign: 'right' }}>
              {e['# of Ships'].toLocaleString()}
            </td>
            <td style={{ textAlign: 'right' }}>
              {['lpg', 'lng'].includes(props.segment)
                ? e.CBM?.toLocaleString()
                : (e.DWT as number).toLocaleString()}
            </td>
            <td style={{ textAlign: 'right' }}>
              {e['w/Scrubber'].toLocaleString()}
            </td>
            <td style={{ textAlign: 'right' }}>
              {e['Orderbook #'].toLocaleString()}
            </td>
            <td style={{ textAlign: 'right' }}>
              {['lpg', 'lng'].includes(props.segment)
                ? (e.OrderbookCBM as number).toLocaleString()
                : (e.OrderbookDWT as number).toLocaleString()}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
  const fleetDevGraph = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          chart: {
            ...highchartStyling.chart,
          },
          series: [
            {
              data: data?.fleetDevelopment.map((e) => [
                new Date(e.Date).getTime(),
                e.DevelopmentShips,
              ]),
              name: 'Fleet development',
              type: 'areaspline',
              color: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                  [0, '#B3DAFF'],
                  [1, '#142E3D'],
                ],
              },
              yAxis: 0,
            },
            {
              data: data?.fleetDevelopment.map((e) => [
                new Date(e.Date).getTime(),
                e.YoYGrowth <= 20 ? e.YoYGrowth : null,
              ]),
              name: 'YoY growth',
              type: 'spline',
              color: '#95D2CA',
              yAxis: 1,
              tooltip: {
                valueSuffix: '%',
                valueDecimals: 2,
              },
            },
          ],
          title: {
            text: 'Fleet development',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          xAxis: {
            ...highchartStyling.xAxis,
            type: 'datetime',
          },
          yAxis: [
            {
              // Primary yAxis
              title: {
                text: '# ships',
              },
              labels: {
                style: {
                  color: '#999',
                  fontWeight: 'bold',
                },
              },
            },
            {
              // Secondary yAxis
              title: {
                text: 'YoY growth',
              },
              labels: {
                format: '{value} %',
                style: {
                  color: '#999',
                  fontWeight: 'bold',
                },
              },
              opposite: true,
            },
          ],
        }}
      />
    </>
  );

  const orderBookGraph = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          chart: {
            ...highchartStyling.chart,
          },
          series: [
            {
              data: data?.orderBook.map((e) => [
                new Date(e.Date).getTime(),
                e.OrderbookShips,
              ]),
              name: 'Orderbook',
              type: 'areaspline',
              color: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                  [0, '#B3DAFF'],
                  [1, '#142E3D'],
                ],
              },
            },
          ],
          title: {
            text: 'Orderbook',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          xAxis: {
            ...highchartStyling.xAxis,
            type: 'datetime',
          },
          yAxis: {
            // Primary yAxis
            title: {
              text: '# ships',
            },
            labels: {
              style: {
                color: '#999',
                fontWeight: 'bold',
              },
            },
          },
        }}
      />
    </>
  );

  const monthlyStats = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          plotOptions: {
            column: {
              borderWidth: 0,
              pointPadding: 0,
              stacking: 'stream',
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: [
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.deliveries,
              ]),
              type: 'column',
              name: 'Deliveries',
              color: '#95D2CA',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.deletions,
              ]),
              type: 'column',
              name: 'Deletions',
              color: '#D46C75',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.keel_laid,
              ]),
              type: 'column',
              name: 'Keel laid',
              color: '#5787b2',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.under_construction,
              ]),
              type: 'column',
              name: 'Under construction',
              color: '#8A84FF',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.launched,
              ]),
              type: 'column',
              name: 'Launched',
              color: '#7FC2FB',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.scheduled,
              ]),
              type: 'column',
              name: 'Scheduled, not commenced',
              color: '#c1c0c0',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                new Date(e.month_start).getTime() < Date.now()
                  ? e.deliveries + e.deletions
                  : null,
              ]),
              type: 'spline',
              name: 'net',
              color: '#bfbfbf',
            },
          ],
          title: {
            text: 'Monthly Deliveries/Orderbook/Deletions',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          xAxis: {
            ...highchartStyling.xAxis,
            type: 'datetime',
          },
          yAxis: {
            // Primary yAxis
            title: {
              text: '# ships',
            },
            labels: {
              style: {
                color: '#999',
                fontWeight: 'bold',
              },
            },
          },
        }}
      />
    </>
  );

  const yearlyStats = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          plotOptions: {
            column: {
              borderWidth: 0,
              pointPadding: 0,
              stacking: 'stream',
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: [
            {
              data: data?.yearlyStats.map((e) => [e.y, e.deliveries]),
              type: 'column',
              name: 'Deliveries',
              color: '#95D2CA',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.deletions]),
              type: 'column',
              name: 'Deletions',
              color: '#D46C75',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.keel_laid]),
              type: 'column',
              name: 'Keel laid',
              color: '#5787b2',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.under_construction]),
              type: 'column',
              name: 'Under construction',
              color: '#8A84FF',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.launched]),
              type: 'column',
              name: 'Launched',
              color: '#7FC2FB',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.scheduled]),
              type: 'column',
              name: 'Scheduled, not commenced',
              color: '#c1c0c0',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.scrap_candidates]),
              type: 'column',
              name: 'Scrap candidates, age 20+',
            },
            {
              data: data?.yearlyStats.map((e) => [
                e.y,
                e.y <= currentYear ? e.deliveries + e.deletions : null,
              ]),
              type: 'spline',
              name: 'net',
              color: '#bfbfbf',
            },
          ],
          title: {
            text: 'Yearly Deliveries/Orderbook/Deletions',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          yAxis: {
            // Primary yAxis
            title: {
              text: '# ships',
            },
            labels: {
              style: {
                color: '#999',
                fontWeight: 'bold',
              },
            },
          },
        }}
      />
    </>
  );
  const ageProfile = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          chart: {
            ...highchartStyling.chart,
            type: 'pie',
          },
          tooltip: {
            pointFormat: '<b>{name}</b> {point.y} ({point.percentage:.1f}%)',
          },
          series: [
            {
              data: [
                {
                  name: '0-4Y',
                  y: data?.ageProfile['0-4Y'],
                  color: '#ed858e',
                },
                {
                  name: '5-9Y',
                  y: data?.ageProfile['5-9Y'],
                  color: '#70a0cb',
                },
                {
                  name: '10-14Y',
                  y: data?.ageProfile['10-14Y'],
                  color: '#789f9a',
                },
                {
                  name: '15-20Y',
                  y: data?.ageProfile['15-20Y'],
                  color: '#000',
                },
                {
                  name: '+20Y',
                  y: data?.ageProfile['+20Y'],
                  color: '#397b8c',
                },
              ],
              innerSize: '50%',
            },
          ],
          title: {
            text: 'Age profile',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
        }}
      />
    </>
  );
  const fleetDevTable = (unit: 'Ships' | 'DWT') => {
    let dataTable: any[];
    if (data) {
      if (unit === 'Ships') {
        dataTable = data.devTableShips;
      } else {
        dataTable = data.devTableDWT;
      }
    } else {
      dataTable = [{}];
    }
    return (
      <Table className="fl-table" striped hover>
        <thead>
          <tr>
            {dataTable[0] &&
              Object.keys(dataTable[0]).map((e, k) => (
                <th
                  key={k}
                  style={{ textAlign: `${e !== 'Y/M' ? 'right' : 'left'}` }}
                >
                  {e}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {dataTable &&
            dataTable.map((el, key) => (
              <tr key={key}>
                {Object.values(el).map((e: any, k) => (
                  <td
                    key={k}
                    style={{
                      textAlign: `${
                        Object.keys(el)[k] !== 'Y/M' ? 'right' : 'left'
                      }`,
                    }}
                  >
                    {e.toLocaleString()}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };
  if (!dataLoaded) return <AFSpinner />;
  return (
    <Container fluid style={{ marginTop: '21px' }}>
      <Row style={{ marginBottom: '21px' }}>
        <Col md={6} sm={12} xs={12}>
          {overviewTable ? overviewTable : <AFSpinner />}
        </Col>
        <Col md={3} sm={12} xs={12}>
          {fleetDevGraph ? fleetDevGraph : <AFSpinner />}
        </Col>
        <Col md={3} sm={12} xs={12}>
          {orderBookGraph ? orderBookGraph : <AFSpinner />}
        </Col>
      </Row>
      <Row md={3} sm={1} xs={1}>
        <Col>{monthlyStats ? monthlyStats : <AFSpinner />}</Col>
        <Col>{yearlyStats ? yearlyStats : <AFSpinner />}</Col>
        <Col>{ageProfile ? ageProfile : <AFSpinner />}</Col>
      </Row>
      <Row md={1} sm={1} xs={1} style={{ marginTop: '21px' }}>
        <Tabs className="custom-tabs" defaultActiveKey="ships">
          <Tab eventKey="ships" title="Fleet Development (ships)">
            {fleetDevTable('Ships') ? fleetDevTable('Ships') : <AFSpinner />}
          </Tab>
          <Tab
            eventKey="dwt"
            title={`Fleet Development (${
              ['lpg', 'lng'].includes(props.segment) ? 'CBM' : 'DWT'
            })`}
          >
            {fleetDevTable('DWT') ? fleetDevTable('DWT') : <AFSpinner />}
          </Tab>
        </Tabs>
      </Row>
    </Container>
  );
};
const GetFleetDetails = (props: DetailProps) => {
  const [data, setData] = useState<FleetStatsDetails>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentYear, setCurrentYear] = useState<number>(2022);

  async function getFleetDetails(segment: string, subsegment: string) {
    const response = await fetchWithSession(
      `/fleet-details?segment=${segment}&subsegment=${subsegment}`
    );
    return await response.json();
  }
  useEffect(() => {
    setDataLoaded(false);
    let dt = new Date();
    setCurrentYear(dt.getFullYear());
    getFleetDetails(props.segment, props.subsegment)
      .then((fleet) => {
        setData(fleet.response);
        setDataLoaded(true);
      })
      .catch((error) => console.warn('error', error));
  }, [props]);

  const fleetDevGraph = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          chart: {
            ...highchartStyling.chart,
          },
          series: [
            {
              data: data?.fleetDevelopment.map((e) => [
                new Date(e.Date).getTime(),
                e.DevelopmentShips,
              ]),
              yAxis: 0,
              type: 'areaspline',
              name: `${props.subsegment} fleet development`,
              color: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                  [0, '#B3DAFF'],
                  [1, '#142E3D'],
                ],
              },
            },
            {
              data: data?.fleetDevelopment.map((e) => [
                new Date(e.Date).getTime(),
                e.YoYGrowth <= 22 ? e.YoYGrowth : null,
              ]),
              yAxis: 1,
              type: 'spline',
              name: 'YoY growth',
              color: '#95D2CA',
              tooltip: {
                valueSuffix: '%',
                valueDecimals: 2,
              },
            },
          ],
          title: {
            text: 'Fleet development',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          xAxis: {
            ...highchartStyling.xAxis,
            type: 'datetime',
          },
          yAxis: [
            {
              // Primary yAxis
              title: {
                text: '# ships',
              },
              labels: {
                style: {
                  color: '#999',
                  fontWeight: 'bold',
                },
              },
            },
            {
              // Secondary yAxis
              title: {
                text: 'YoY growth',
              },
              labels: {
                format: '{value} %',
                style: {
                  color: '#999',
                  fontWeight: 'bold',
                },
              },
              opposite: true,
            },
          ],
        }}
      />
    </>
  );
  const orderBookGraph = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          chart: {
            ...highchartStyling.chart,
            type: 'areaspline',
          },
          series: [
            {
              data: data?.orderBook.map((e) => [
                new Date(e.Date).getTime(),
                e.OrderbookShips,
              ]),
              name: 'Orderbook',
              color: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                  [0, '#B3DAFF'],
                  [1, '#142E3D'],
                ],
              },
            },
          ],
          title: {
            text: 'Orderbook',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          xAxis: {
            ...highchartStyling.xAxis,
            type: 'datetime',
          },
          yAxis: {
            // Primary yAxis
            title: {
              text: '#ships',
            },
            labels: {
              style: {
                color: '#999',
                fontWeight: 'bold',
              },
            },
          },
        }}
      />
    </>
  );
  const monthlyStats = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          plotOptions: {
            column: {
              borderWidth: 0,
              pointPadding: 0,
              stacking: 'stream',
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: [
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.deliveries,
              ]),
              type: 'column',
              name: 'Deliveries',
              color: '#95D2CA',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.deletions,
              ]),
              type: 'column',
              name: 'Deletions',
              color: '#D46C75',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.keel_laid,
              ]),
              type: 'column',
              name: 'Keel laid',
              color: '#5787b2',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.under_construction,
              ]),
              type: 'column',
              name: 'Under construction',
              color: '#8A84FF',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.launched,
              ]),
              type: 'column',
              name: 'Launched',
              color: '#7FC2FB',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                e.scheduled,
              ]),
              type: 'column',
              name: 'Scheduled, not commenced',
              color: '#c1c0c0',
            },
            {
              data: data?.monthlyStats.map((e) => [
                new Date(e.month_start).getTime(),
                new Date(e.month_start).getTime() < Date.now()
                  ? e.deliveries + e.deletions
                  : null,
              ]),
              type: 'spline',
              name: 'net',
              color: '#bfbfbf',
            },
          ],
          title: {
            text: 'Monthly Deliveries/Orderbook/Deletions',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          xAxis: {
            ...highchartStyling.xAxis,
            type: 'datetime',
          },
          yAxis: {
            // Primary yAxis
            title: {
              text: '# ships',
            },
            labels: {
              style: {
                color: '#999',
                fontWeight: 'bold',
              },
            },
          },
        }}
      />
    </>
  );

  const yearlyStats = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          plotOptions: {
            column: {
              borderWidth: 0,
              pointPadding: 0,
              stacking: 'stream',
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: [
            {
              data: data?.yearlyStats.map((e) => [e.y, e.deliveries]),
              type: 'column',
              name: 'Deliveries',
              color: '#95D2CA',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.deletions]),
              type: 'column',
              name: 'Deletions',
              color: '#D46C75',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.keel_laid]),
              type: 'column',
              name: 'Keel laid',
              color: '#5787b2',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.under_construction]),
              type: 'column',
              name: 'Under construction',
              color: '#8A84FF',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.launched]),
              type: 'column',
              name: 'Launched',
              color: '#7FC2FB',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.scheduled]),
              type: 'column',
              name: 'Scheduled, not commenced',
              color: '#c1c0c0',
            },
            {
              data: data?.yearlyStats.map((e) => [e.y, e.scrap_candidates]),
              type: 'column',
              name: 'Scrap candidates, age 20+',
            },
            {
              data: data?.yearlyStats.map((e) => [
                e.y,
                e.y <= currentYear ? e.deliveries + e.deletions : null,
              ]),
              type: 'spline',
              name: 'net',
              color: '#bfbfbf',
            },
          ],
          title: {
            text: 'Yearly Deliveries/Orderbook/Deletions',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
          yAxis: {
            // Primary yAxis
            title: {
              text: '# ships',
            },
            labels: {
              style: {
                color: '#999',
                fontWeight: 'bold',
              },
            },
          },
        }}
      />
    </>
  );
  const ageProfile = (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...highchartStyling,
          chart: {
            ...highchartStyling.chart,
            type: 'pie',
          },
          tooltip: {
            pointFormat: '<b>{name}</b> {point.y} ({point.percentage:.1f}%)',
          },
          series: [
            {
              data: [
                {
                  name: '0-4Y',
                  y: data?.ageProfile['0-4Y'],
                  color: '#ed858e',
                },
                {
                  name: '5-9Y',
                  y: data?.ageProfile['5-9Y'],
                  color: '#70a0cb',
                },
                {
                  name: '10-14Y',
                  y: data?.ageProfile['10-14Y'],
                  color: '#789f9a',
                },
                {
                  name: '15-20Y',
                  y: data?.ageProfile['15-20Y'],
                  color: '#000',
                },
                {
                  name: '+20Y',
                  y: data?.ageProfile['+20Y'],
                  color: '#397b8c',
                },
              ],
              innerSize: '50%',
            },
          ],
          title: {
            text: 'Age profile',
            style: {
              color: '#FFF',
              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
            },
          },
        }}
      />
    </>
  );
  const latestVessels = (metric: 'orders' | 'deletions' | 'deliveries') => {
    let dataTable: any[] = [{}];
    if (!data) {
      dataTable = [{}];
    } else {
      if (metric === 'orders') {
        dataTable = data.latestOrders;
      }
      if (metric === 'deletions') {
        dataTable = data.latestDeletions;
      }
      if (metric === 'deliveries') {
        dataTable = data.latestDeliveries;
      }
    }
    if (!dataTable[0])
      return (
        <h5
          style={{
            fontFamily: 'sans-serif',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          No {metric} to display
        </h5>
      );
    return (
      <Table className="fl-table" striped hover>
        <thead>
          <tr>
            {dataTable &&
              Object.keys(dataTable[0]).map((e, k) => <th key={k}>{e}</th>)}
          </tr>
        </thead>
        <tbody>
          {dataTable &&
            dataTable.map((el, key) => (
              <tr key={key}>
                {Object.values(el).map((e: any, k) => (
                  <td key={k}>
                    {Object.keys(el)[k] !== 'IMO' ? e?.toLocaleString() : e}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };
  if (!dataLoaded) return <AFSpinner />;
  return (
    <Container fluid style={{ marginTop: '21px' }}>
      <Row md={2} sm={1} xs={1} style={{ marginBottom: '21px' }}>
        <Col>{fleetDevGraph}</Col>
        <Col>{orderBookGraph}</Col>
      </Row>
      <Row md={3} sm={1} xs={1}>
        <Col>{monthlyStats}</Col>
        <Col>{yearlyStats}</Col>
        <Col>{ageProfile}</Col>
      </Row>
      <Row md={1} sm={1} xs={1} style={{ marginTop: '21px' }}>
        <Tabs className="custom-tabs" defaultActiveKey="orders">
          <Tab eventKey="orders" title="Latest orders">
            {latestVessels('orders')}
          </Tab>
          <Tab eventKey="deletions" title="Latest deletions">
            {latestVessels('deletions')}
          </Tab>
          <Tab eventKey="deliveries" title="Latest deliveries">
            {latestVessels('deliveries')}
          </Tab>
        </Tabs>
      </Row>
    </Container>
  );
};
export function FleetData({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  return (
    <div className="middle-stuff">
      {(segmentStateValue as string) === SegmentMatch.DryCargo && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          className="custom-tabs"
          defaultActiveKey="overview"
        >
          <Tab eventKey="overview" title="Overview">
            <GetFleetOverview segment="dry_bulk" />
          </Tab>
          <Tab eventKey="capesize" title="Capesize/Newcastlemax">
            <GetFleetDetails segment="dry_bulk" subsegment="Capesize" />
          </Tab>
          <Tab eventKey="panamax" title="Panamax/Kamsarmax">
            <GetFleetDetails segment="dry_bulk" subsegment="Panamax" />
          </Tab>
          <Tab eventKey="supramax" title="Supramax/Ultramax">
            <GetFleetDetails segment="dry_bulk" subsegment="Supra/Ultramax" />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.Tankers && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          className="custom-tabs"
          defaultActiveKey="overview"
        >
          <Tab eventKey="overview" title="Overview">
            <GetFleetOverview segment="tanker" />
          </Tab>
          <Tab eventKey="vlcc" title="VLCC">
            <GetFleetDetails segment="tanker" subsegment="VLCC" />
          </Tab>
          <Tab eventKey="suezmax" title="Suezmax">
            <GetFleetDetails segment="tanker" subsegment="Suezmax" />
          </Tab>
          <Tab eventKey="aframax" title="Aframax/LR2">
            <GetFleetDetails segment="tanker" subsegment="Aframax" />
          </Tab>
          <Tab eventKey="panamax" title="Panamax/LR1">
            <GetFleetDetails segment="tanker" subsegment="Panamax" />
          </Tab>
          <Tab eventKey="handy" title="MR/Handy">
            <GetFleetDetails segment="tanker" subsegment="MR" />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.LPG && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          className="custom-tabs"
          defaultActiveKey="overview"
        >
          <Tab eventKey="overview" title="Overview">
            <GetFleetOverview segment="lpg" />
          </Tab>
          <Tab eventKey="vlgc" title="VLGC">
            <GetFleetDetails segment="lpg" subsegment="VLGC" />
          </Tab>
          <Tab eventKey="lgc" title="LGC">
            <GetFleetDetails segment="lpg" subsegment="LGC" />
          </Tab>
          <Tab eventKey="mgc" title="Midsize">
            <GetFleetDetails segment="lpg" subsegment="Midsize" />
          </Tab>
          <Tab eventKey="handysize" title="Handysize">
            <GetFleetDetails segment="lpg" subsegment="Handysize" />
          </Tab>
        </Tabs>
      )}
      {segmentStateValue === SegmentMatch.LNG && (
        <Tabs
          mountOnEnter
          unmountOnExit
          transition={false}
          className="custom-tabs"
          defaultActiveKey="overview"
        >
          <Tab eventKey="overview" title="Overview">
            <GetFleetOverview segment="lng" />
          </Tab>
          <Tab eventKey="lngc" title="LNGC">
            <GetFleetDetails segment="lng" subsegment="LNGC" />
          </Tab>
          <Tab eventKey="gasprocessing" title="Gas Processing">
            <GetFleetDetails segment="lng" subsegment="Gas Processing" />
          </Tab>
          <Tab eventKey="sslng" title="SSLNG">
            <GetFleetDetails segment="lng" subsegment="SSLNG" />
          </Tab>
        </Tabs>
      )}
    </div>
  );
}
