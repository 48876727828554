export function runScriptTagTicker_LPG() {
  const scriptTagTicker = document.createElement('script');
  scriptTagTicker.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
  scriptTagTicker.async = true;
  scriptTagTicker.innerHTML = `
          {
            "symbols": [
              {
                "title": "Brent",
                "proName": "TVC:UKOIL"
              },
              {
                "title": "USD/NOK",
                "proName": "FX_IDC:USDNOK"
              },
              {
                "title": "USD/EUR",
                "proName": "FX_IDC:USDEUR"
              },
              {
                "title": "USD/KRW",
                "proName": "FX_IDC:USDKRW"
              },
              {
                "title": "USD/JPY",
                "proName": "OANDA:USDJPY"
              }
            ],
            "colorTheme": "dark",
            "isTransparent": true,
            "locale": "en"
          }
          `;
  return scriptTagTicker;
}
