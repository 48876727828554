import toast from 'react-hot-toast';

export function customizedToast(toastMsg: string, icon: string): void {
  toast(toastMsg, {
    duration: 3000,
    position: 'top-center',

    // Styling
    style: {},
    className: '',

    // Custom Icon
    icon: icon,

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },

    // Aria
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
}
