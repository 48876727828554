import { fetchWithSession } from 'components/fetchWithSession';
import { useEffect, useState } from 'react';

export function useIsLogedIn() {
  const [loading, setIsLoading] = useState<Boolean>(true);
  const [loginStateVal, setLoginStateVal] = useState<Boolean>(false);

  useEffect(() => {
    async function checkAuth(): Promise<void> {
      // const input: RequestInfo = getAPIPath() + '/auth/status';
      const response = await fetchWithSession('/auth/status');

      const bod = await response.json();
      const { isAuth } = bod;
      setLoginStateVal(isAuth);
      setIsLoading(false);
    }
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, loginStateVal };
}
