import React from 'react';
import Select from 'react-select'
import { MultiSelectOption, MultiSelectOptions } from '../state/searchVessel';

const style1 = {
  control: (base: any, state: any) => { 
    return {
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "0px",
    outline: '0',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    borderColor: state.isFocused ? '#ced4da' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(227, 223, 217, 0.90);' : '0 !important',
    '&:hover': { borderColor: '#ced4da' }, // border style on hover
  }
}
};

interface Props { 
  options: MultiSelectOptions,
  name: string,
  stateSetter: (name: string, value: any) => void,
  value: MultiSelectOptions|MultiSelectOption|undefined,
  isMulti?: boolean
}

const FOSBSMultiSelect = (props: Props) => {

  const isMulti = props.isMulti !== false;

  return <Select 
    key={`select-${props.name}`}
    onChange={(vals) => {
      props.stateSetter(props.name, vals);
    }}
    isMulti={isMulti}
    value={props.value}
    options={props.options}
    styles={style1}
    isClearable={true}
  />
}

function areEqual(prevProps: Props, nextProps: Props) {
  // due to async fetching of options, we must also check if options are empty or 
  const equal = prevProps.value === nextProps.value && prevProps.options.length === nextProps.options.length;
  return equal;
}


export default React.memo(FOSBSMultiSelect, areEqual);
