import { useEffect, useState } from 'react';
import { fetchWithSession } from './fetchWithSession';
import { identifyUser } from './TopHeader';

export function hasMailerPageAccess(userEmail: string): boolean {
  const emailsWithAccess = [
    'M.Sunde@fearnleys.com',
    'l.brusletto@fearnleys.com',
  ];

  const hasAccess: boolean = emailsWithAccess
    .map((obj) => obj?.toLowerCase())
    .includes(userEmail?.toLowerCase());
  return hasAccess;
}

export function useGetUserAndGetAcessRights(shouldIdentifyUser: boolean): {
  name: string;
  hasMailAccess: boolean;
} {
  const [name, setName] = useState<string>('Super Broker');
  const [hasMailAccess, setHasMailAccess] = useState<boolean>(false);

  useEffect(() => {
    async function getName() {
      try {
        const response = await fetchWithSession('/user-info');
        const body = await response.json();
        setName(body.name);

        setHasMailAccess(hasMailerPageAccess(body.email));

        shouldIdentifyUser && identifyUser(body.name);
      } catch (error) {
        console.error(error);
      }
    }
    getName();
  }, []);

  return { name, hasMailAccess };
}
