import { useEffect, useRef, useState } from 'react';
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasTitle,
  Spinner,
} from 'react-bootstrap';
import { getTitleForSegment } from 'state/segment';
import FOSBSBoolean from '../../common/FOSBSBoolean';
import FOSBSMultiSelect from '../../common/FOSBSMultiSelect';
import FOSBSRange from '../../common/FOSBSRange';
import FOSBSTextInput from '../../common/FOSBSTextInput';
import { getAPIPath } from '../../getAPIPath';
import { convertToFleetSearchPayload } from '../../mapper/fleet-search';
import {
  defaultFleetGeneralForm,
  FormPart,
  GeneralForm,
  MultiSelectOptions,
} from '../../state/searchFleet';
import { NewSearchResults } from './NewSearchResults';

const defaultDisplayFields = [
  // TODO: add dynamic display fields -- currently prefixed!
  'IMO',
  'Vessel',
  'ShipType',
  'ShipSize',
  'DateOfBuild',
  'DeadWeight',
  'GasCapacity',
  'IceClass',
  'Scrubber',
  'Shipbuilder',
  'RegisteredOwner',
  'ShipStatus',
  'CurrentStatus',
  'DeathDate',
];

function useShipData() {
  // data lists
  const [shipStatuses, setShipStatuses] = useState<MultiSelectOptions>([]);
  const [iceClasses, setIceClasses] = useState<MultiSelectOptions>([]);

  useEffect(() => {
    const loadData = async () => {
      const mapToMultiSelect = (rawArr: string[]): MultiSelectOptions => {
        return rawArr.map((el) => {
          return { label: el, value: el };
        });
      };
      setShipStatuses(
        mapToMultiSelect([
          'Broken Up',
          'Cancelled Before Construction',
          'Converting/Rebuilding',
          'In Casualty Or Repairing',
          'In Service/Commission',
          'Keel Laid',
          'Laid-Up',
          'Launched',
          'On Order/Not Commenced',
          'Projected',
          'To Be Broken Up',
          'Total Loss',
          'Under Construction',
        ])
      );
      setIceClasses(mapToMultiSelect(['1A', '1A+', '1B', '1C']));
    };

    loadData();
  }, []);

  return { shipStatuses, iceClasses };
}

export default function NewSearch({
  segmentStateValue,
  segment,
  subsegments,
}: {
  segmentStateValue: string;
  segment: string;
  subsegments: string[];
}) {
  const querySegment = segment;

  // search params
  const [searched, setSearched] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  // const [design, setDesign] = useState<MultiSelectOptions>([]);

  // form states
  const [generalFormState, setGeneralFormState] = useState<GeneralForm>(
    defaultFleetGeneralForm
  );

  // search result states
  const [searchResults, setSearchResults] = useState<any>([]);

  // form state refs
  const latestGeneralFormState = useRef(generalFormState);

  // subscribe for state updates (needed due to use of memoized components)
  useEffect(
    () => {
      latestGeneralFormState.current = generalFormState;
    },
    // deps to watch for changes in
    [generalFormState]
  );
  const { shipStatuses, iceClasses } = useShipData();

  const getPayload = () => {
    // Convert to payolad
    const payload = convertToFleetSearchPayload({
      general: generalFormState,
    });

    const finalRequestBody = {
      request: payload,
      displayFields: defaultDisplayFields,
    };

    return finalRequestBody;
  };

  async function doSearch(keepHistoryData: any): Promise<void> {
    setSearched(false);
    setSearchResults([]);

    const finalRequestBody = getPayload();
    const res = await fetch(getAPIPath() + `/search/fleet/${querySegment}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'content-type': 'Application/JSON',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(finalRequestBody),
    });

    if (res.status !== 200) {
      // TODO: Handle load error
    }

    const searchResponse = await res.json();

    const fleetMapped = searchResponse.fleet.map((el: any) => {
      return {
        //id: `${el.IMO}`,
        ...el,
      };
    });

    // If keeping previous results -> add them to the results array
    const newSearchResults =
      keepHistoryData !== undefined
        ? [...keepHistoryData, ...fleetMapped]
        : fleetMapped;

    const newSearchResultsFiltered = newSearchResults.filter(
      (el: any, index: any) => {
        return (
          newSearchResults.findIndex((matchEl: any) => {
            return matchEl.IMO === el.IMO; // match on IMO
          }) === index
        );
      }
    );

    setSearchResults(newSearchResultsFiltered);

    setSearched(true);
    setCollapsed(true);
  }

  const doReset = () => {
    setGeneralFormState(defaultFleetGeneralForm);
  };

  const handleClose = () => setCollapsed(true);

  function setFormStateElement(formPart: FormPart, field: string, value: any) {
    const existingValue = latestGeneralFormState.current;

    const newObject: GeneralForm = {
      ...existingValue,
      [field]: value,
    };

    setGeneralFormState(newObject);
  }

  return (
    <>
      <div className="container-fluid">
        <p className="pt-3" style={{ fontFamily: 'Segoe UI' }}>
          <a
            className="btn btn-dark btn-md"
            href="#toggle"
            role="button"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            Search {'>>'}
          </a>
        </p>

        <Offcanvas show={!collapsed} onHide={handleClose} scroll>
          <OffcanvasHeader closeButton>
            <OffcanvasTitle>{`Search ${getTitleForSegment(
              segmentStateValue
            )} Vessel`}</OffcanvasTitle>
          </OffcanvasHeader>
          <hr />
          <div className="row" style={{ overflow: 'auto' }}>
            <div style={{ padding: 18 }}>
              {/* <div className="col-sm-12 col-md-6 col-lg-6 bg-light pt-2"> */}
              {/* <div className="col-md-12">
              <h5 className="fw-light pt-3 border-bottom border-5 border-secondary pb-1">Search vessel</h5>
            </div> */}

              <FOSBSTextInput
                label={'Imo Number'}
                value={generalFormState.IMO}
                onChange={(e) => {
                  setFormStateElement(FormPart.General, 'IMO', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Vessel Name'}
                value={generalFormState.Vessel}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'Vessel',
                    e.target.value
                  );
                }}
              />
              <FOSBSRange
                label="Build date"
                name="DateOfBuild"
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.DateOfBuild}
              />
              <div className="row">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-4 col-form-label"
                >
                  Vessel Subtype
                </label>
                <div className="col">
                  <FOSBSMultiSelect
                    name="ShipSize"
                    value={generalFormState.ShipSize}
                    options={subsegments.map((el) => {
                      return { label: el, value: el };
                    })}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-4 col-form-label"
                >
                  Ship status
                </label>
                <div className="col">
                  <FOSBSMultiSelect
                    name="ShipStatus"
                    value={generalFormState.ShipStatus}
                    options={shipStatuses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-4 col-form-label"
                >
                  Ice class
                </label>
                <div className="col">
                  <FOSBSMultiSelect
                    name="IceClass"
                    value={generalFormState.IceClass}
                    options={iceClasses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>
              <FOSBSRange
                label="Scrap date"
                name="DeathDate"
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.DeathDate}
              />

              <FOSBSRange
                label="DWT"
                name="DeadWeight"
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.DeadWeight}
              />
              <FOSBSRange
                label="Gas Capacity"
                name="GasCapacity"
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.GasCapacity}
              />
              <FOSBSBoolean
                label={'Scrubber'}
                name={'Scrubber'}
                value={generalFormState.Scrubber}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <hr />
            <div
              className="col-md-12"
              style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
            >
              <button
                className="btn btn-success"
                style={{ marginRight: 5 }}
                onClick={() => {
                  doSearch(undefined);
                }}
              >
                Search
              </button>
              <button
                className="btn btn-secondary"
                style={{ marginRight: 5 }}
                onClick={doReset}
              >
                Reset
              </button>
              {/* {searched && <button className='btn btn-warning' onClick={doSearchKeepHistory}>Add to Previous Search</button>} */}
            </div>
          </div>
        </Offcanvas>
        {/* end collapse */}
      </div>

      <div className="container-fluid">
        <div className="row pt-3 ">
          {!searched && (
            <div style={{ textAlign: 'right' }}>
              <Spinner animation="border" />
            </div>
          )}
          <div className="col-sm-12 col-md-12 col-lg-12">
            {searched && (
              <span className="btn btn-light mb-3">
                Matches{' '}
                <span className="badge" style={{ backgroundColor: '#2B5B5F' }}>
                  {searchResults.length}
                </span>
              </span>
            )}

            {/* Search results! */}
            {searched && <NewSearchResults dataFromSearch={searchResults} />}
          </div>
        </div>
      </div>
    </>
  );
}
