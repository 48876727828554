export enum NavPages {
  Main,
  RatesAndAssetValues,
  FFA,
  BunkerPrices,
  TradeIndicators,
  FleetData,
  CargoFlow,
  FleetSearch,
  Mailer,
}

export const navPaths = {
  [NavPages.Main]: '/home',
  [NavPages.RatesAndAssetValues]: '/rates',
  [NavPages.FFA]: '/ffa',
  [NavPages.BunkerPrices]: '/bunkerprices',
  [NavPages.TradeIndicators]: '/marketdata',
  [NavPages.FleetData]: '/fleetdata',
  [NavPages.CargoFlow]: '/cargoflow',
  [NavPages.FleetSearch]: '/fleet-search',
  [NavPages.Mailer]: '/mailer',
};
