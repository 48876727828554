import Footer from './components/Index/Footer';
import TopHeader from './components/TopHeader';

export function LayoutPage({
  children,
  segmentStateValue,
}: {
  children: any;
  segmentStateValue: string;
}): JSX.Element {
  return (
    <div className="tw-h-screen">
      <TopHeader segmentStateValue={segmentStateValue} />
      <div>{children}</div>
      <div className={'tw-sticky tw-top-[100vh]'}>
        <Footer />
      </div>
    </div>
  );
}
