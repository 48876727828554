import NewSearch from '../Search/Fleet/NewSearch';
import { SegmentMatch } from '../state/segment';

export function FleetSearch({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  return (
    <div className="middle-stuff-fleet-search">
      {segmentStateValue === SegmentMatch.DryCargo && (
        <NewSearch
          segmentStateValue={segmentStateValue}
          segment={'dry_bulk'}
          subsegments={[
            'Supra/Ultramax',
            'Capesize',
            'Handymax',
            'VLOC',
            'Handysize',
            'Panamax',
          ]}
        />
      )}
      {segmentStateValue === SegmentMatch.Tankers && (
        <NewSearch
          segmentStateValue={segmentStateValue}
          segment={'tanker'}
          subsegments={[
            'Aframax',
            'LR2',
            'Handy',
            'Suezmax',
            'VLCC',
            'Panamax',
            'MR',
            'LR1',
          ]}
        />
      )}
      {segmentStateValue === SegmentMatch.LPG && (
        <NewSearch
          segmentStateValue={segmentStateValue}
          segment={'lpg'}
          subsegments={['LGC', 'Midsize', 'Handysize', 'Smallsize', 'VLGC']}
        />
      )}
      {segmentStateValue === SegmentMatch.LNG && (
        <NewSearch
          segmentStateValue={segmentStateValue}
          segment={'lng'}
          subsegments={['LNGC', 'Gas Processing', 'SSLNG']}
        />
      )}
    </div>
  );
}
