//#region TYPES (TODO: Move)
export interface MultiSelectFormState {
  selectedValues: any[];
  textValue: string;
}

export interface MultiSelectOption {
  label: string;
  value: any;
}

export type MultiSelectOptions = MultiSelectOption[];

export interface RangeFormState {
  from: string;
  to: string;
}

//#endregion

//#region Form Interfaces

export enum FormPart {
  General,
}

export interface GeneralForm {
  IMO: string;
  Vessel: string;
  ShipType: MultiSelectOptions;
  ShipSize: MultiSelectOptions;
  DateOfBuild: RangeFormState;
  DeadWeight: RangeFormState;
  GasCapacity: RangeFormState;
  IceClass: MultiSelectOptions;
  Scrubber: string;
  Shipbuilder: MultiSelectOptions;
  RegisteredOwner: MultiSelectOptions;
  ShipStatus: MultiSelectOptions;
  CurrentStatus: MultiSelectOptions;
  DeathDate: RangeFormState;
}

// --- Default vals ---

export const defaultFleetGeneralForm: GeneralForm = {
  IMO: '',
  Vessel: '',
  ShipType: [],
  ShipSize: [],
  DateOfBuild: { from: '', to: '' },
  DeadWeight: { from: '', to: '' },
  GasCapacity: { from: '', to: '' },
  IceClass: [],
  Scrubber: '',
  Shipbuilder: [],
  RegisteredOwner: [],
  ShipStatus: [],
  CurrentStatus: [],
  DeathDate: { from: '', to: '' },
};
