import { useState } from 'react';
import { Nav, Navbar, NavbarBrand, NavDropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getRouteUrlLink } from 'Root';
import { useAnalytics } from 'use-analytics';
import '../index.css';
import { NavPages, navPaths } from '../routing';
import analytics from '../util/analytics';
import { SegmentChanger } from './SegmentChanger';
import { useGetUserAndGetAcessRights } from './useGetUserAndGetAcessRights';

export function identifyUser(name: string) {
  if (name !== 'Super Broker') {
    analytics.identify(name, {
      name: name,
    });
  }
}

export default function TopHeader({
  segmentStateValue,
}: {
  segmentStateValue: string;
}) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { track } = useAnalytics();
  const navigate = useNavigate();

  const locationObj = useLocation();
  const currentPath = locationObj?.pathname;

  const pathFromUrl = currentPath.split('/')[1];
  const pathFromUrlToMAtchWithLink = '/'.concat(pathFromUrl);

  const { name, hasMailAccess } = useGetUserAndGetAcessRights(true);

  const curHr = new Date().getHours();
  return (
    <Navbar
      className="navbar-gradientß pt-2 pb-2 mb-2"
      bg="dark"
      variant="dark"
      expand="lg"
      sticky="top"
      expanded={expanded}
    >
      <Navbar.Brand
        onClick={() =>
          navigate(
            getRouteUrlLink(segmentStateValue, navPaths[NavPages.Main]),
            { replace: false }
          )
        }
        style={{ padding: '10px' }}
      >
        <img
          src="/images/logo.png"
          className={'fearnleys-logo'}
          alt={'Fearnleys Logo'}
        />
      </Navbar.Brand>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav defaultActiveKey={navPaths[NavPages.Main]}>
          <Nav.Item
            onClick={() => {
              setExpanded(false);
              navigate(
                getRouteUrlLink(segmentStateValue, navPaths[NavPages.Main]),
                { replace: false }
              );
            }}
          >
            <Nav.Link
              active={pathFromUrlToMAtchWithLink === navPaths[NavPages.Main]}
            >
              {'Home'}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              track('click on Rates & Values');
              setExpanded(false);
              navigate(
                getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.RatesAndAssetValues]
                ),
                { replace: true }
              );
            }}
          >
            <Nav.Link
              active={
                pathFromUrlToMAtchWithLink ===
                navPaths[NavPages.RatesAndAssetValues]
              }
            >
              {'Rates & Values'}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              track('click on FFA');
              setExpanded(false);
              navigate(
                getRouteUrlLink(segmentStateValue, navPaths[NavPages.FFA]),
                { replace: true }
              );
            }}
          >
            <Nav.Link
              active={pathFromUrlToMAtchWithLink === navPaths[NavPages.FFA]}
            >
              {'FFA'}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              track('click on FFA');
              setExpanded(false);

              navigate(
                getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.BunkerPrices]
                ),
                { replace: true }
              );
            }}
          >
            <Nav.Link
              active={
                pathFromUrlToMAtchWithLink === navPaths[NavPages.BunkerPrices]
              }
            >
              {'Bunker Prices'}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              track('click on Trade Indicators');
              setExpanded(false);
              navigate(
                getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.TradeIndicators]
                ),
                { replace: true }
              );
            }}
          >
            <Nav.Link
              active={
                pathFromUrlToMAtchWithLink ===
                navPaths[NavPages.TradeIndicators]
              }
            >
              {'Trade Indicators'}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item
            onClick={() => {
              track('click on cargoflow');
              setExpanded(false);
              navigate(
                getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.CargoFlow]
                ),
                { replace: true }
              );
            }}
          >
            <Nav.Link
              eventKey={getRouteUrlLink(
                segmentStateValue,
                navPaths[NavPages.CargoFlow]
              )}
            >
              {'Cargo Flow'}
            </Nav.Link>
          </Nav.Item>

          <NavDropdown
            active={
              pathFromUrlToMAtchWithLink === navPaths[NavPages.FleetSearch]
            }
            title="Fleet Data"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item>
              {/* <Nav.Item
                onClick={() => {
                  track('click on Fleet Stats');
                  setExpanded(false);
                  navigate(
                    getRouteUrlLink(
                      segmentStateValue,
                      navPaths[NavPages.FleetData]
                    ),
                    { replace: true }
                  );
                }}
              > */}
              <Nav.Link
                onClick={() => {
                  track('click on Fleet Stats');
                  setExpanded(false);
                  navigate(
                    getRouteUrlLink(
                      segmentStateValue,
                      navPaths[NavPages.FleetData]
                    ),
                    { replace: true }
                  );
                }}
                eventKey={getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.FleetData]
                )}
                as={Link}
                to={getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.FleetData]
                )}
              >
                {'Fleet Stats'}
              </Nav.Link>
              {/* </Nav.Item> */}
            </NavDropdown.Item>
            <NavDropdown.Item>
              {/* <Nav.Item> */}
              <Nav.Link
                onClick={() => {
                  track('click on Fleet Search');
                  setExpanded(false);
                  navigate(
                    getRouteUrlLink(
                      segmentStateValue,
                      navPaths[NavPages.FleetSearch]
                    ),
                    { replace: true }
                  );
                }}
                // eventKey={navPaths[NavPages.FleetSearch]}
                as={Link}
                to={getRouteUrlLink(
                  segmentStateValue,
                  navPaths[NavPages.FleetSearch]
                )}
              >
                {'Fleet Search'}
              </Nav.Link>
              {/* </Nav.Item> */}
            </NavDropdown.Item>
          </NavDropdown>
          {hasMailAccess && (
            <Nav.Item
              onClick={() => {
                track('click on Mailer');
                setExpanded(false);
                navigate(
                  getRouteUrlLink(segmentStateValue, navPaths[NavPages.Mailer]),
                  { replace: true }
                );
              }}
            >
              <Nav.Link
                active={
                  pathFromUrlToMAtchWithLink === navPaths[NavPages.Mailer]
                }
              >
                {'Mailer'}
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Nav className="ms-auto">
          <NavbarBrand style={{ fontSize: '14px', marginTop: '4px' }}>{`Good ${
            curHr < 12 ? 'morning' : curHr < 18 ? 'afternoon' : 'evening'
          }, ${name}.`}</NavbarBrand>
          <Nav.Item>
            {' '}
            <SegmentChanger />{' '}
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
