export const cleanupIndexJScripts = () => {
  let classNames = [
    'twitter-mother',
    'tradingview-widget-container-1',
    'tradingview-widget-container-2',
    'tradingview-widget-container-3',
    'tradingview-widget-container-4',
    'tradingview-widget-container-5',
    'tradingview-widget-container-6',
  ];
  for (let className of classNames) {
    const docElem = document.getElementsByClassName(className);
    if (docElem === undefined || docElem.length === 0) continue; // did not find elem, skip
    docElem[0].innerHTML = ''; // Set content to zero
  }
};
