import Analytics from 'analytics';
import fullStoryPlugin from '@analytics/fullstory';
import mixpanelPlugin from '@analytics/mixpanel';
import { history } from '../EnbaleTracking';
import Cookies from 'js-cookie';

const REACT_APP_FULLSTORY_ORG_ID = 'o-1AANDF-na1';
const REACT_APP_MIXPANEL_PROJECT_TOKEN = 'ce7da8e37dc551b38c24da7cbda3cf30';
// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
  debug: true, // process.env.NODE_ENV !== 'production',
  plugins: [
    fullStoryPlugin({
      org: REACT_APP_FULLSTORY_ORG_ID, // process.env.REACT_APP_FULLSTORY_ORG_ID
    }),
    mixpanelPlugin({
      token: REACT_APP_MIXPANEL_PROJECT_TOKEN,
    }),
  ],
});

// Track initial pageview
if (typeof window !== 'undefined') {
  analytics.page();
}

// Track pageview on route change
history.listen(() => {
  analytics.page();
});

if (Cookies.get('analyse') !== 'true') {
  Cookies.set('analyse', 'true', { expires: 7 });
  analytics.enablePlugin(['fullstory', 'mixpanel']);
}

export default analytics;
